"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractsState = void 0;
class ContractsState {
    constructor({ loading, contracts, totalContracts }) {
        this.loading = loading;
        this.contracts = contracts;
        this.totalContracts = totalContracts;
    }
    static factory() {
        return new ContractsState({
            loading: false,
            contracts: [],
            totalContracts: 0
        });
    }
}
exports.ContractsState = ContractsState;
