"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomersState = void 0;
class CustomersState {
    constructor({ loading, customers, customerGroups, currentCustomer }) {
        this.loading = loading;
        this.customers = customers;
        this.customerGroups = customerGroups;
        this.currentCustomer = currentCustomer;
    }
    static factory() {
        return new CustomersState({
            loading: false,
            customers: [],
            customerGroups: [],
            currentCustomer: null
        });
    }
}
exports.CustomersState = CustomersState;
