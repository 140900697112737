"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    {
        name: 'api_banner_files_active',
        path: '/api/banner-files/active',
        redirect: '',
        meta: { requiresAuth: true }
    },
];
