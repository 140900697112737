"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    {
        name: 'axapta_balance_api',
        path: '/api/axapta/balance/:id(\\d+)',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'axapta_invoices_api',
        path: '/api/axapta/invoices',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'axapta_send_invoice_copy_in_email_api',
        path: '/api/axapta/invoices/send-invoice-copy-in-email',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'axapta_invoice_items_api',
        path: '/api/axapta/invoice-items',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'axapta_packing_slips_api',
        path: '/api/axapta/packing-slips',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'axapta_send_packing_slip_copy_in_email_api',
        path: '/api/axapta/packing-slips/send-packing-slip-copy-in-email',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'axapta_packing_slip_items_api',
        path: '/api/axapta/packing-slip-items',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'axapta_orders_api',
        path: '/api/axapta/orders',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'axapta_order_items_api',
        path: '/api/axapta/order-items',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'axapta_order_delivery_infos_api',
        path: '/api/axapta/order-delivery-infos',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'axapta_send_transferred_orders_report_in_email_api',
        path: '/api/axapta/orders/send-transferred-orders-report-in-email',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'axapta_open_orders_api',
        path: '/api/axapta/open-orders',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'axapta_guarantee_product_returns_api',
        path: '/api/axapta/guarantee-product-returns',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'axapta_customer_product_returns_api',
        path: '/api/axapta/customer-product-returns',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'axapta_customer_product_return_list_api',
        path: '/api/axapta/customer-product-return-list',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'axapta_create_customer_product_returns_api',
        path: '/api/axapta/create-customer-product-returns',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'axapta_send_guarantee_product_return_copy_in_email_api',
        path: '/api/axapta/guarantee-product-returns/send-guarantee-product-return-copy-in-email',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'axapta_send_customer_product_return_copy_in_email_api',
        path: '/api/axapta/customer-product-returns/send-customer-product-return-copy-in-email',
        redirect: '',
        meta: { requiresAuth: true }
    },
];
