"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { key: 1 };
const vue_router_1 = require("vue-router");
exports.default = (0, vue_1.defineComponent)({
    __name: 'NavMenuItem',
    props: {
        to: {
            type: [String, Object],
        },
        title: {
            type: String,
            default: "",
        },
        target: {
            type: String,
            default: "_self",
        }
    },
    emits: ["selected"],
    setup(__props, { emit }) {
        const props = __props;
        const router = (0, vue_router_1.useRouter)();
        const onSelect = () => {
            emit("selected");
            if (props.to) {
                router.push(props.to);
            }
        };
        return (_ctx, _cache) => {
            const _component_router_link = (0, vue_2.resolveComponent)("router-link");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                class: (0, vue_2.normalizeClass)(["flex items-center", { 'cursor-pointer': __props.to }]),
                onClick: onSelect
            }, [
                (__props.to)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_router_link, {
                        key: 0,
                        to: __props.to,
                        class: "text-black lg:text-white h-full flex items-center",
                        title: __props.title,
                        target: __props.target
                    }, {
                        default: (0, vue_2.withCtx)(() => [
                            (0, vue_2.renderSlot)(_ctx.$slots, "default")
                        ]),
                        _: 3
                    }, 8, ["to", "title", "target"]))
                    : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_1, [
                        (0, vue_2.renderSlot)(_ctx.$slots, "default")
                    ]))
            ], 2));
        };
    }
});
