"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ShippingModeEnum_1 = require("./ShippingModeEnum");
const validators_1 = require("../../common/utils/validators");
const PermissionEnum_1 = require("../../stores/permission/PermissionEnum");
class CreateOrderRequest {
    constructor(createdCartId, note = '', shippingModeId = null, shippingGlsFlexDeliveryEmail = null, shippingGlsFlexDeliveryPhone = null, shippingCustomerAddressId = null, shippingCustomAddressName = null, shippingCustomAddressCountryCode = null, shippingCustomAddressZipCode = null, shippingCustomAddressCity = null, shippingCustomAddressStreet = null, shippingPickupPointId = null, //site id
    shippingPersonalPickupPointId = null, //site id
    paymentModeId = null, currentUser = null) {
        this.createdCartId = createdCartId;
        this.note = note;
        this.shippingModeId = shippingModeId;
        this.shippingGlsFlexDeliveryEmail = shippingGlsFlexDeliveryEmail;
        this.shippingGlsFlexDeliveryPhone = shippingGlsFlexDeliveryPhone;
        this.shippingCustomerAddressId = shippingCustomerAddressId;
        this.shippingCustomAddressName = shippingCustomAddressName;
        this.shippingCustomAddressCountryCode = shippingCustomAddressCountryCode;
        this.shippingCustomAddressZipCode = shippingCustomAddressZipCode;
        this.shippingCustomAddressCity = shippingCustomAddressCity;
        this.shippingCustomAddressStreet = shippingCustomAddressStreet;
        this.shippingPickupPointId = shippingPickupPointId;
        this.shippingPersonalPickupPointId = shippingPersonalPickupPointId;
        this.paymentModeId = paymentModeId;
        this.currentUser = currentUser;
    }
    isValid() {
        var _a, _b;
        if (this.shippingModeId === null) {
            return false;
        }
        if (this.shippingModeId === ShippingModeEnum_1.ShippingModeEnum.GLS_FLEX_DELIVERY
            && (this.shippingGlsFlexDeliveryPhone === null
                || this.shippingGlsFlexDeliveryPhone === ''
                || this.shippingGlsFlexDeliveryEmail === null
                || this.shippingGlsFlexDeliveryEmail === ''
                || !(0, validators_1.isValidEmail)(this.shippingGlsFlexDeliveryEmail))) {
            return false;
        }
        if (this.shippingModeId === ShippingModeEnum_1.ShippingModeEnum.PERSONAL_AT_PICKUP_POINT
            && this.shippingPickupPointId === null) {
            return false;
        }
        if (this.shippingModeId === ShippingModeEnum_1.ShippingModeEnum.PERSONAL) {
            // ha nem export partner és az átvételi pont id null akkor nem valid a request
            if (!((_a = this.currentUser) === null || _a === void 0 ? void 0 : _a.hasPermission(PermissionEnum_1.CustomerPermissionEnum.EXPORT_PARTNER)) && this.shippingPersonalPickupPointId === null) {
                return false;
            }
            // ha export partner és az átvételi pont id nem null akkor nem valid a request
            if (((_b = this.currentUser) === null || _b === void 0 ? void 0 : _b.hasPermission(PermissionEnum_1.CustomerPermissionEnum.EXPORT_PARTNER)) && this.shippingPersonalPickupPointId != null) {
                return false;
            }
        }
        if ([ShippingModeEnum_1.ShippingModeEnum.COURIER, ShippingModeEnum_1.ShippingModeEnum.EXPRESS, ShippingModeEnum_1.ShippingModeEnum.GLS_FLEX_DELIVERY].includes(this.shippingModeId)
            && this.shippingCustomerAddressId === null) {
            return false;
        }
        if ([ShippingModeEnum_1.ShippingModeEnum.COURIER, ShippingModeEnum_1.ShippingModeEnum.EXPRESS, ShippingModeEnum_1.ShippingModeEnum.GLS_FLEX_DELIVERY].includes(this.shippingModeId)
            && this.shippingCustomerAddressId === 'custom'
            && (this.shippingCustomAddressName === null
                || this.shippingCustomAddressName === ''
                || this.shippingCustomAddressZipCode === null
                || this.shippingCustomAddressZipCode === ''
                || this.shippingCustomAddressCity === null
                || this.shippingCustomAddressCity === ''
                || this.shippingCustomAddressStreet === null
                || this.shippingCustomAddressStreet === '')) {
            return false;
        }
        if (this.paymentModeId === null) {
            return false;
        }
        return true;
    }
}
exports.default = CreateOrderRequest;
