"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFieldDefinition = void 0;
function isFieldDefinition(arg) {
    return typeof arg === 'object'
        && arg !== null
        && arg.hasOwnProperty('key')
        && arg.hasOwnProperty('label');
}
exports.isFieldDefinition = isFieldDefinition;
