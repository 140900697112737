"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const storeObjects_1 = require("./storeObjects");
exports.default = {
    namespaced: true,
    state: storeObjects_1.state,
    actions: storeObjects_1.actions,
    mutations: storeObjects_1.mutations,
    getters: storeObjects_1.getters,
};
