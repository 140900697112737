"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductListState = void 0;
class ProductListState {
    constructor({ loading, list }) {
        this.loading = loading;
        this.list = list;
    }
    hasStockDetailsLoaded(id) {
        let found = false;
        this.list.forEach((item) => {
            var _a, _b;
            if (item.id === id) {
                if ((_b = (_a = item.stocks) === null || _a === void 0 ? void 0 : _a.stockDetails) === null || _b === void 0 ? void 0 : _b.length) {
                    found = true;
                }
            }
        });
        return found;
    }
    attachDiscountTheme(products) {
        let discountIds = [];
        let themeIndex;
        let i;
        products.forEach((product, index) => {
            if (product.discounts && product.discounts.length) {
                product.discounts.forEach((discount) => {
                    i = discountIds.indexOf(discount.id);
                    if (-1 == i) {
                        // not found, add to
                        themeIndex = discountIds.push(discount.id);
                        discount.class = 'discount-theme-' + themeIndex;
                    }
                    else {
                        discount.class = 'discount-theme-' + (i + 1);
                    }
                });
            }
        });
        return products;
    }
    static factory(list = []) {
        return new ProductListState({ loading: false, list });
    }
}
exports.ProductListState = ProductListState;
