"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.valveProductSearchForm = exports.rimProductSearchForm = exports.accessoriesProductSearchForm = exports.defaultProductSearchForm = exports.tapeProductSearchForm = exports.tubeProductSearchForm = exports.calculateTypeFromAxCategory = void 0;
const ProductFilter_1 = require("../../models/ProductFilter");
const calculateTypeFromAxCategory = (axCategory = '') => {
    if (['210', '220', '230', '240', '250', '260', '270', '280'].includes(axCategory)) {
        return 'tube';
    }
    if (['310', '320', '350', '360', '370'].includes(axCategory)) {
        return 'tape';
    }
    if (['720', '730', '740', '750', '755'].includes(axCategory)) {
        return 'accessories';
    }
    if (['410', '415', '420', '440', '445'].includes(axCategory)) {
        return 'rim';
    }
    if (['530'].includes(axCategory)) {
        return 'valve';
    }
    return 'default';
};
exports.calculateTypeFromAxCategory = calculateTypeFromAxCategory;
exports.tubeProductSearchForm = Object.assign(Object.keys(ProductFilter_1.TubeProductFilters).reduce((o, key) => ({ ...o, [key]: null }), {}), { type: 'tube' });
exports.tapeProductSearchForm = Object.assign(Object.keys(ProductFilter_1.TapeProductFilters).reduce((o, key) => ({ ...o, [key]: null }), {}), { type: 'tape' });
exports.defaultProductSearchForm = Object.assign(Object.keys(ProductFilter_1.ProductFilters).reduce((o, key) => ({ ...o, [key]: null }), {}), { type: 'default' });
exports.accessoriesProductSearchForm = Object.assign(Object.keys(ProductFilter_1.AccessoriesProductFilters).reduce((o, key) => ({ ...o, [key]: null }), {}), { type: 'accessories' });
exports.rimProductSearchForm = Object.assign(Object.keys(ProductFilter_1.RimProductFilters).reduce((o, key) => ({ ...o, [key]: null }), {}), { type: 'rim' });
exports.valveProductSearchForm = Object.assign(Object.keys(ProductFilter_1.ValveProductFilters).reduce((o, key) => ({ ...o, [key]: null }), {}), { type: 'valve' });
