"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpenOrdersState = void 0;
class OpenOrdersState {
    constructor({ openOrders }) {
        this.openOrders = openOrders;
    }
    static factory() {
        return new OpenOrdersState({
            openOrders: []
        });
    }
}
exports.OpenOrdersState = OpenOrdersState;
