"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigsState = void 0;
class ConfigsState {
    constructor({ bannedAxaptaProductImportRules, bannedProductSearchRules }) {
        this.bannedAxaptaProductImportRules = bannedAxaptaProductImportRules;
        this.bannedProductSearchRules = bannedProductSearchRules;
    }
    static factory() {
        return new ConfigsState({
            bannedAxaptaProductImportRules: [],
            bannedProductSearchRules: []
        });
    }
}
exports.ConfigsState = ConfigsState;
