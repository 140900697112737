"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerProductReturnReasonCodes = void 0;
var CustomerProductReturnReasonCodes;
(function (CustomerProductReturnReasonCodes) {
    CustomerProductReturnReasonCodes[CustomerProductReturnReasonCodes["WRONG_DELIVERY"] = 1] = "WRONG_DELIVERY";
    CustomerProductReturnReasonCodes[CustomerProductReturnReasonCodes["WRONG_ORDER"] = 2] = "WRONG_ORDER";
    CustomerProductReturnReasonCodes[CustomerProductReturnReasonCodes["WRONG_DOT"] = 3] = "WRONG_DOT";
    CustomerProductReturnReasonCodes[CustomerProductReturnReasonCodes["OTHER"] = 4] = "OTHER";
})(CustomerProductReturnReasonCodes = exports.CustomerProductReturnReasonCodes || (exports.CustomerProductReturnReasonCodes = {}));
