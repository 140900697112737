"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuaranteeProductReturnCreateRequest = void 0;
const validators_1 = require("../../common/utils/validators");
const shop_1 = require("../../common/utils/shop");
class GuaranteeProductReturnCreateRequest {
    constructor(causedAccident = false, causedPersonalInjury = false, requireTyreReturn = false, causedMaterialDamage = false, customerComplaint = '', purchaseDate = '', customerInvoiceId = '', base64EncodedInvoiceFile = null, customer = null, //Customer IRI
    customerEqualsEndUser = false, complainantName = '', complaintDate = (0, shop_1.formatDate)(new Date()), guaranteeCardNumber = '', endUserName = '', endUserZipCode = '', endUserCity = '', endUserStreet = '', endUserMobile = '', endUserEmail = '', plateNumber = null, vehicleType = null, //VehicleType IRI
    vehicleBrand = null, //VehicleBrand IRI
    vehicleModel = null, //VehicleModel IRI
    vehicleVintage = null, vehicleOperatingHours = null, vehicleRegistrationDate = null, vehicleMileage = null, vehicleChassisNumber = null, cubicCentimeter = null, performanceKw = null, electronicVehicle = false, product = null, //Product IRI
    tyrePosition = null, //TyrePosition IRI
    residualProfileDepth = null, operatingPressure = null, dotNumber = '', productionNumber = null, firstMounting = false, mainUseArea = null, dataAccurate = false, filterpid = null, filterpname = null, guaranteeType = null) {
        this.causedAccident = causedAccident;
        this.causedPersonalInjury = causedPersonalInjury;
        this.requireTyreReturn = requireTyreReturn;
        this.causedMaterialDamage = causedMaterialDamage;
        this.customerComplaint = customerComplaint;
        this.purchaseDate = purchaseDate;
        this.customerInvoiceId = customerInvoiceId;
        this.base64EncodedInvoiceFile = base64EncodedInvoiceFile;
        this.customer = customer;
        this.customerEqualsEndUser = customerEqualsEndUser;
        this.complainantName = complainantName;
        this.complaintDate = complaintDate;
        this.guaranteeCardNumber = guaranteeCardNumber;
        this.endUserName = endUserName;
        this.endUserZipCode = endUserZipCode;
        this.endUserCity = endUserCity;
        this.endUserStreet = endUserStreet;
        this.endUserMobile = endUserMobile;
        this.endUserEmail = endUserEmail;
        this.plateNumber = plateNumber;
        this.vehicleType = vehicleType;
        this.vehicleBrand = vehicleBrand;
        this.vehicleModel = vehicleModel;
        this.vehicleVintage = vehicleVintage;
        this.vehicleOperatingHours = vehicleOperatingHours;
        this.vehicleRegistrationDate = vehicleRegistrationDate;
        this.vehicleMileage = vehicleMileage;
        this.vehicleChassisNumber = vehicleChassisNumber;
        this.cubicCentimeter = cubicCentimeter;
        this.performanceKw = performanceKw;
        this.electronicVehicle = electronicVehicle;
        this.product = product;
        this.tyrePosition = tyrePosition;
        this.residualProfileDepth = residualProfileDepth;
        this.operatingPressure = operatingPressure;
        this.dotNumber = dotNumber;
        this.productionNumber = productionNumber;
        this.firstMounting = firstMounting;
        this.mainUseArea = mainUseArea;
        this.dataAccurate = dataAccurate;
        this.filterpid = filterpid;
        this.filterpname = filterpname;
        this.guaranteeType = guaranteeType;
    }
    isPurchaseDateValid() {
        //TODO purchaseDate cant be bigger than now
        return !(0, validators_1.isBlank)(this.purchaseDate);
    }
    isCustomerInvoiceIdValid() {
        return !(0, validators_1.isBlank)(this.customerInvoiceId);
    }
    isBase64EncodedInvoiceFileValid() {
        return !(0, validators_1.isBlank)(this.base64EncodedInvoiceFile);
    }
    isCustomerComplaintValid() {
        return !(0, validators_1.isBlank)(this.customerComplaint);
    }
    isComplaintDateValid() {
        return !(0, validators_1.isBlank)(this.complaintDate);
    }
    isComplainantNameValid() {
        return !(0, validators_1.isBlank)(this.complainantName);
    }
    isPlateNumberValid(vehicleType) {
        return !((vehicleType === null && (0, validators_1.isBlank)(this.plateNumber))
            || (vehicleType !== null && vehicleType.requiredPlateNumber && (0, validators_1.isBlank)(this.plateNumber)));
    }
    isVehicleTypeValid() {
        return !(0, validators_1.isBlank)(this.vehicleType);
    }
    isVehicleBrandValid(vehicleType) {
        return !((vehicleType === null && (0, validators_1.isBlank)(this.vehicleBrand))
            || (vehicleType !== null && vehicleType.requiredBrand && (0, validators_1.isBlank)(this.vehicleBrand)));
    }
    isVehicleModelValid(vehicleType) {
        return !((vehicleType === null && (0, validators_1.isBlank)(this.vehicleModel))
            || (vehicleType !== null && vehicleType.requiredModel && (0, validators_1.isBlank)(this.vehicleModel)));
    }
    isVehicleVintageValid(vehicleType) {
        return !((vehicleType === null && (0, validators_1.isBlank)(this.vehicleVintage))
            || (vehicleType !== null && vehicleType.requiredVintage && (0, validators_1.isBlank)(this.vehicleVintage)));
    }
    isVehicleRegistrationDateValid(vehicleType) {
        return !((vehicleType === null && (0, validators_1.isBlank)(this.vehicleRegistrationDate))
            || (vehicleType !== null && vehicleType.requiredRegistrationDate && (0, validators_1.isBlank)(this.vehicleRegistrationDate)));
    }
    isVehicleMileageValid(vehicleType) {
        return !((vehicleType === null && (0, validators_1.isBlank)(this.vehicleMileage))
            || (vehicleType !== null && vehicleType.requiredMileage && (0, validators_1.isBlank)(this.vehicleMileage)));
    }
    isVehicleOperatingHoursValid(vehicleType) {
        return !((vehicleType === null && (0, validators_1.isBlank)(this.vehicleOperatingHours))
            || (vehicleType !== null && vehicleType.requiredOperatingHours && (0, validators_1.isBlank)(this.vehicleOperatingHours)));
    }
    isVehicleChassisNumberValid(vehicleType) {
        return !((vehicleType === null && (0, validators_1.isBlank)(this.vehicleChassisNumber))
            || (vehicleType !== null && vehicleType.requiredChassisNumber && (0, validators_1.isBlank)(this.vehicleChassisNumber)));
    }
    isCubicCentimeterValid() {
        return !(0, validators_1.isBlank)(this.cubicCentimeter);
    }
    isPerformanceKwValid() {
        return !(0, validators_1.isBlank)(this.performanceKw);
    }
    isDOTNumberValid() {
        return !(0, validators_1.isBlank)(this.dotNumber);
    }
    isProductionNumberValid(vehicleType) {
        return !((vehicleType === null && (0, validators_1.isBlank)(this.productionNumber))
            || (vehicleType !== null && vehicleType.requiredProductionNumber && (0, validators_1.isBlank)(this.productionNumber)));
    }
    isMainUseAreaValid(vehicleType) {
        return !((vehicleType === null && (0, validators_1.isBlank)(this.mainUseArea))
            || (vehicleType !== null && vehicleType.requiredMainUseArea && (0, validators_1.isBlank)(this.mainUseArea)));
    }
    isProductIRIValid() {
        return !(0, validators_1.isBlank)(this.product);
    }
    isTyrePositionValid() {
        return !(0, validators_1.isBlank)(this.tyrePosition);
    }
    isResidualProfileDepthValid() {
        return !(0, validators_1.isBlank)(this.residualProfileDepth);
    }
    isOperatingPressureValid() {
        return !(0, validators_1.isBlank)(this.operatingPressure);
    }
    isDataAccurate() {
        return this.dataAccurate;
    }
    isGuaranteeTypeValid() {
        return !(0, validators_1.isBlank)(this.guaranteeType);
    }
    isValid(vehicleType) {
        if (vehicleType === null) {
            return false;
        }
        return this.isPurchaseDateValid()
            && this.isCustomerInvoiceIdValid()
            && this.isBase64EncodedInvoiceFileValid()
            && this.isCustomerComplaintValid()
            && this.isComplaintDateValid()
            && this.isComplainantNameValid()
            && this.isPlateNumberValid(vehicleType)
            && this.isVehicleTypeValid()
            && this.isVehicleBrandValid(vehicleType)
            && this.isVehicleModelValid(vehicleType)
            && this.isVehicleVintageValid(vehicleType)
            && this.isVehicleRegistrationDateValid(vehicleType)
            && this.isVehicleMileageValid(vehicleType)
            && this.isVehicleOperatingHoursValid(vehicleType)
            && this.isVehicleChassisNumberValid(vehicleType)
            && this.isCubicCentimeterValid()
            && this.isPerformanceKwValid()
            && this.isDOTNumberValid()
            && this.isProductionNumberValid(vehicleType)
            && this.isMainUseAreaValid(vehicleType)
            && this.isProductIRIValid()
            && this.isTyrePositionValid()
            && this.isResidualProfileDepthValid()
            && this.isOperatingPressureValid()
            && this.isDataAccurate();
    }
}
exports.GuaranteeProductReturnCreateRequest = GuaranteeProductReturnCreateRequest;
