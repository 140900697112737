"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const shop_1 = require("../../common/utils/shop");
class BannerRequest {
    constructor(createdUser, //IRI
    createdDatetime = (0, shop_1.formatDate)(new Date()), title, status = null, datePublish = null, dateDePublish = null, mainPageUrl = null, searchPageUrl = null, universalUrl = null, displayMainPageBanner = false, displaySearchPageBanner = false, displayUniversalBanner = false, searchPageBannerFilter = []) {
        this.createdUser = createdUser;
        this.createdDatetime = createdDatetime;
        this.title = title;
        this.status = status;
        this.datePublish = datePublish;
        this.dateDePublish = dateDePublish;
        this.mainPageUrl = mainPageUrl;
        this.searchPageUrl = searchPageUrl;
        this.universalUrl = universalUrl;
        this.displayMainPageBanner = displayMainPageBanner;
        this.displaySearchPageBanner = displaySearchPageBanner;
        this.displayUniversalBanner = displayUniversalBanner;
        this.searchPageBannerFilter = searchPageBannerFilter;
    }
}
exports.default = BannerRequest;
