"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewsFileState = void 0;
class NewsFileState {
    constructor({ loading, newsFiles }) {
        this.loading = loading;
        this.newsFiles = newsFiles;
    }
    static factory() {
        return new NewsFileState({
            loading: false,
            newsFiles: []
        });
    }
}
exports.NewsFileState = NewsFileState;
