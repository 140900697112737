"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BalanceState = void 0;
class BalanceState {
    constructor({ balance }) {
        this.balance = balance;
    }
    static factory() {
        return new BalanceState({
            balance: null
        });
    }
}
exports.BalanceState = BalanceState;
