"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "w-full 2xl:container mx-auto p-4 flex flex-col" };
const _hoisted_2 = { class: "my-5 border rounded-lg p-3 bg-white shadow" };
const _hoisted_3 = { class: "font-bold mb-3" };
const _hoisted_4 = { class: "font-bold" };
const _hoisted_5 = { class: "font-bold" };
const _hoisted_6 = { class: "font-bold" };
const _hoisted_7 = ["innerHTML"];
const _hoisted_8 = { class: "my-5 border rounded-lg p-3 bg-white shadow" };
const _hoisted_9 = { class: "font-bold mb-3" };
const _hoisted_10 = { class: "font-bold" };
const _hoisted_11 = { class: "font-bold" };
const _hoisted_12 = { class: "font-bold" };
const _hoisted_13 = ["innerHTML"];
const _hoisted_14 = { class: "font-bold" };
const _hoisted_15 = ["innerHTML"];
const vue_3 = require("vue");
const vuex_1 = require("vuex");
const i18n_1 = require("../../common/i18n");
//LOCAL VARIABLES
exports.default = (0, vue_1.defineComponent)({
    __name: 'AdminConfigs',
    setup(__props) {
        const store = (0, vuex_1.useStore)();
        //COMPUTED
        const bannedAxaptaProductImportRules = (0, vue_3.computed)(() => store.getters["configs/bannedAxaptaProductImportRules"]);
        const bannedProductSearchRules = (0, vue_3.computed)(() => store.getters["configs/bannedProductSearchRules"]);
        (0, vue_3.onBeforeMount)(() => {
            store.dispatch("configs/getBannedAxaptaProductImportRules");
            store.dispatch("configs/getBannedProductSearchRules");
        });
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createElementVNode)("div", _hoisted_3, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("banned_axapta_product_import_rules", "Banned Axapta Product Import Rules", "configs")), 1),
                    ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)((0, vue_2.unref)(bannedAxaptaProductImportRules), (rule, index) => {
                        return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", { key: index }, [
                            (0, vue_2.createElementVNode)("div", _hoisted_4, (0, vue_2.toDisplayString)(index + 1) + ". " + (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("banned_axapta_product_import_rules_rule", "Rule", "configs")), 1),
                            (0, vue_2.createElementVNode)("div", null, [
                                (0, vue_2.createElementVNode)("span", _hoisted_5, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("banned_axapta_product_import_rules_description", "Description", "configs")) + ":", 1),
                                (0, vue_2.createTextVNode)(" " + (0, vue_2.toDisplayString)(rule.description), 1)
                            ]),
                            (0, vue_2.createElementVNode)("div", _hoisted_6, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("banned_axapta_product_import_rules_filters", "Filters", "configs")) + ": ", 1),
                            (0, vue_2.createElementVNode)("div", {
                                class: "my-3",
                                innerHTML: rule.formattedFilters
                            }, null, 8, _hoisted_7)
                        ]));
                    }), 128))
                ]),
                (0, vue_2.createElementVNode)("div", _hoisted_8, [
                    (0, vue_2.createElementVNode)("div", _hoisted_9, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("banned_product_search_rules", "Banned Product Search Rules", "configs")), 1),
                    ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)((0, vue_2.unref)(bannedProductSearchRules), (rule, index) => {
                        return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", { key: index }, [
                            (0, vue_2.createElementVNode)("div", _hoisted_10, (0, vue_2.toDisplayString)(index + 1) + ". " + (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("banned_product_search_rules_rule", "Rule", "configs")), 1),
                            (0, vue_2.createElementVNode)("div", null, [
                                (0, vue_2.createElementVNode)("span", _hoisted_11, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("banned_product_search_rules_description", "Description", "configs")) + ":", 1),
                                (0, vue_2.createTextVNode)(" " + (0, vue_2.toDisplayString)(rule.description), 1)
                            ]),
                            (0, vue_2.createElementVNode)("div", _hoisted_12, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("banned_product_search_rules_conditions", "Conditions", "configs")) + ": ", 1),
                            (0, vue_2.createElementVNode)("div", {
                                class: "my-3",
                                innerHTML: rule.formattedConditions
                            }, null, 8, _hoisted_13),
                            (0, vue_2.createElementVNode)("div", _hoisted_14, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("banned_product_search_rules_filters", "Filters", "configs")) + ": ", 1),
                            (0, vue_2.createElementVNode)("div", {
                                class: "my-3",
                                innerHTML: rule.formattedFilters
                            }, null, 8, _hoisted_15)
                        ]));
                    }), 128))
                ])
            ]));
        };
    }
});
