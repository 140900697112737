"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductPopupState = exports.Statuses = void 0;
var Statuses;
(function (Statuses) {
    Statuses[Statuses["suspended"] = 0] = "suspended";
    Statuses[Statuses["loading"] = 1] = "loading";
    Statuses[Statuses["notFound"] = 2] = "notFound";
    Statuses[Statuses["showing"] = 3] = "showing";
})(Statuses = exports.Statuses || (exports.Statuses = {}));
class ProductPopupState {
    constructor() {
        this.widgetState = Statuses.suspended;
        this.product = null;
        this.productSku = null;
        this.productData = null;
    }
    modalIsOpen() {
        return this.widgetState !== Statuses.suspended;
    }
    modalIsLoading() {
        return this.widgetState === Statuses.loading;
    }
    isNotFound() {
        return this.widgetState === Statuses.notFound;
    }
    static factory() {
        return new ProductPopupState();
    }
}
exports.ProductPopupState = ProductPopupState;
