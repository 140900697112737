"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesContactsState = void 0;
class SalesContactsState {
    constructor({ salesContacts }) {
        this.salesContacts = salesContacts;
    }
    static factory() {
        return new SalesContactsState({
            salesContacts: []
        });
    }
}
exports.SalesContactsState = SalesContactsState;
