"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CouponRequest = void 0;
class CouponRequest {
    constructor(customer = '', //IRI
    name = '', description = '', originalValue = 0, remainValue = 0, beginDate = '', endDate = '') {
        this.customer = customer;
        this.name = name;
        this.description = description;
        this.originalValue = originalValue;
        this.remainValue = remainValue;
        this.beginDate = beginDate;
        this.endDate = endDate;
    }
}
exports.CouponRequest = CouponRequest;
