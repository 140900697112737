"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = ["aria-checked"];
const vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'Toggle',
    props: {
        label: {
            type: String,
            default: '',
        },
        modelValue: [Boolean, Number, String],
        checked: Boolean,
        disabled: Boolean,
        reversed: Boolean,
        valid: {
            type: Boolean,
            default: true,
            required: false
        },
        showValidity: {
            type: Boolean,
            default: false,
        }
    },
    emits: ["update:modelValue", "change"],
    setup(__props, { emit }) {
        var _a;
        const props = __props;
        const isChecked = (0, vue_3.ref)(props.checked || ((_a = Boolean(props.modelValue).valueOf()) !== null && _a !== void 0 ? _a : false));
        const onToggleClicked = () => {
            if (props.disabled) {
                return;
            }
            isChecked.value = !isChecked.value;
            emit('update:modelValue', isChecked.value);
            emit('change', isChecked.value);
        };
        // update isChecked when modelValue updated from the calling component
        (0, vue_3.watch)(() => props.modelValue, val => {
            var _a;
            isChecked.value = (_a = Boolean(val).valueOf()) !== null && _a !== void 0 ? _a : false;
        });
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("label", {
                class: (0, vue_2.normalizeClass)(["toggle flex gap-1 cursor-pointer justify-start items-center", { disabled: __props.disabled }]),
                onClick: onToggleClicked
            }, [
                (__props.label.length)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", {
                        key: 0,
                        innerHTML: __props.label
                    }, null, 8, _hoisted_1))
                    : (0, vue_2.renderSlot)(_ctx.$slots, "label", { key: 1 }),
                (0, vue_2.createElementVNode)("div", {
                    class: (0, vue_2.normalizeClass)(["w-14 h-8 lg:w-8 lg:h-4 flex items-center rounded-full p-1 duration-150 cursor-pointer", {
                            'bg-gray-dark': !isChecked.value,
                            'bg-green': isChecked.value,
                            'bg-red': !__props.valid,
                            'order-first': __props.reversed
                        }]),
                    "aria-checked": isChecked.value
                }, [
                    (0, vue_2.createElementVNode)("div", {
                        class: (0, vue_2.normalizeClass)(["bg-white w-6 h-6 lg:w-2 lg:h-2 rounded-full shadow-md transform-gpu duration-150", { 'translate-x-6': isChecked.value, 'lg:translate-x-4': isChecked.value }])
                    }, null, 2)
                ], 10, _hoisted_2)
            ], 2));
        };
    }
});
