"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NEWS_CATEGORIES = exports.COMPANY_NEWS_CATEGORY = exports.MG_NEWS_CATEGORY = exports.TGK_NEWS_CATEGORY = exports.SZGK_NEWS_CATEGORY = void 0;
exports.SZGK_NEWS_CATEGORY = "szgk";
exports.TGK_NEWS_CATEGORY = "tgk";
exports.MG_NEWS_CATEGORY = "mg";
exports.COMPANY_NEWS_CATEGORY = "company";
exports.NEWS_CATEGORIES = [
    exports.SZGK_NEWS_CATEGORY,
    exports.TGK_NEWS_CATEGORY,
    exports.MG_NEWS_CATEGORY,
    exports.COMPANY_NEWS_CATEGORY
];
