"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewsState = void 0;
class NewsState {
    constructor({ loading, news, article, totalNews }) {
        this.loading = loading;
        this.news = news;
        this.article = article;
        this.totalNews = totalNews;
    }
    static factory() {
        return new NewsState({
            loading: false,
            news: [],
            article: null,
            totalNews: 0
        });
    }
}
exports.NewsState = NewsState;
