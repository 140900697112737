"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "w-full 2xl:container mx-auto px-4" };
const _hoisted_2 = { class: "uppercase md:text-xl lg:text-2xl xl:text-3xl font-bold mb-6 py-4 border-b" };
const _hoisted_3 = { class: "flex flex-col md:flex-row items-start" };
const _hoisted_4 = { class: "flex items-start w-full pb-2 md:pr-3.5" };
const _hoisted_5 = { class: "grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3" };
const _hoisted_6 = { class: "relative rounded-lg overflow-hidden" };
const _hoisted_7 = {
    target: "_blank",
    href: "https://marsokft.sharepoint.com/:f:/s/Tudaster/Ento9M8JXORPkvR7DDu3M5oBip5iqlsdOUSIDYq7eViIKw?e=oW3p7z",
    class: "information-link block w-full h-full"
};
const _hoisted_8 = ["src", "alt"];
const _hoisted_9 = { class: "absolute left-5 bottom-5 bg-opacity-75 bg-gray-darkest text-white font-bold py-2 px-4 rounded border" };
const _hoisted_10 = { class: "relative rounded-lg overflow-hidden" };
const _hoisted_11 = {
    target: "_blank",
    href: "https://marsokft.sharepoint.com/:f:/s/Tudaster/Eti8hLmt7LZPp6A-8jRCVZUB3URbhyWNWGFVewapFpjLww?e=aDCggi",
    class: "information-link block w-full h-full"
};
const _hoisted_12 = ["src", "alt"];
const _hoisted_13 = { class: "absolute left-5 bottom-5 bg-opacity-75 bg-gray-darkest text-white font-bold py-2 px-4 rounded border" };
const _hoisted_14 = { class: "relative rounded-lg overflow-hidden" };
const _hoisted_15 = {
    target: "_blank",
    href: "https://marsokft.sharepoint.com/:f:/s/Tudaster/EnKpaGJOhLxMi_5WSs59J10BBL4L2qTVA-wa5pF512QSTA?e=9PRJPo",
    class: "information-link block w-full h-full"
};
const _hoisted_16 = ["src", "alt"];
const _hoisted_17 = { class: "absolute left-5 bottom-5 bg-opacity-75 bg-gray-darkest text-white font-bold py-2 px-4 rounded border" };
const _hoisted_18 = { class: "relative rounded-lg overflow-hidden" };
const _hoisted_19 = {
    target: "_blank",
    href: "https://marsokft.sharepoint.com/:f:/s/Tudaster/EmWdOICjAo9AnR_waan8Lt0BN53mV-SyXqZXo3i8lgHy_Q?e=SmK9wd",
    class: "information-link block w-full h-full"
};
const _hoisted_20 = ["src", "alt"];
const _hoisted_21 = { class: "absolute left-5 bottom-5 bg-opacity-75 bg-gray-darkest text-white font-bold py-2 px-4 rounded border" };
const _hoisted_22 = { class: "relative rounded-lg overflow-hidden" };
const _hoisted_23 = {
    target: "_blank",
    href: "https://marsokft.sharepoint.com/:f:/s/Tudaster/Etd8SRpPEstHhTH1zQlCfZwBJEpPCnGJSn824rbIsWlvOQ?e=ZxGIGR",
    class: "information-link block w-full h-full"
};
const _hoisted_24 = ["src", "alt"];
const _hoisted_25 = { class: "absolute left-5 bottom-5 bg-opacity-75 bg-gray-darkest text-white font-bold py-2 px-4 rounded border" };
const _hoisted_26 = { class: "relative rounded-lg overflow-hidden" };
const _hoisted_27 = {
    target: "_blank",
    href: "https://marsokft.sharepoint.com/:f:/s/Tudaster/EvbAq6O5NyBCk4tMdVIIgIYB31oL7SeBUSyhdWs5UeroyA",
    class: "information-link block w-full h-full"
};
const _hoisted_28 = ["src", "alt"];
const _hoisted_29 = { class: "absolute left-5 bottom-5 bg-opacity-75 bg-gray-darkest text-white font-bold py-2 px-4 rounded border" };
const _hoisted_30 = { class: "flex flex-col w-full md:w-auto p-4 mb-2 shadow rounded bg-white" };
const _hoisted_31 = { class: "uppercase font-bold py-4" };
const _hoisted_32 = { class: "pb-3 border-b-1 border-gray flex flex-col justify-between leading-normal" };
const _hoisted_33 = { class: "mb-2" };
const _hoisted_34 = { class: "text-gray-900 font-bold text-l mb-2" };
const _hoisted_35 = { class: "bg-gray flex items-stretch pr-4 items-center" };
const _hoisted_36 = { class: "flex items-center p-1 uppercase bg-[#222] text-white font-bold mr-2 text-[12px]" };
const _hoisted_37 = {
    key: 0,
    class: "flex font-light p-1 text-[14px]"
};
const _hoisted_38 = {
    key: 1,
    class: "flex font-light p-1 text-[14px]"
};
const i18n_1 = require("../../common/i18n");
const vue_3 = require("vue");
const informationPageStore_1 = require("../../stores/informationPage/informationPageStore");
const vuex_1 = require("vuex");
const vue_router_1 = require("vue-router");
exports.default = (0, vue_1.defineComponent)({
    __name: 'InformationPage',
    setup(__props) {
        const store = (0, vuex_1.useStore)();
        const router = (0, vue_router_1.useRouter)();
        const showMoreUpdate = (0, vue_3.ref)(false);
        const informationPageStore = (0, informationPageStore_1.useInformationPageStore)();
        (0, vue_3.onBeforeMount)(() => {
            informationPageStore.getUpdateBoxItems(router);
        });
        const showMoreUpdates = () => {
            showMoreUpdate.value = true;
        };
        const showLessUpdates = () => {
            showMoreUpdate.value = false;
        };
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("h3", _hoisted_2, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("information_title", `Information page`, "information_page")), 1),
                (0, vue_2.createElementVNode)("div", _hoisted_3, [
                    (0, vue_2.createElementVNode)("div", _hoisted_4, [
                        (0, vue_2.createElementVNode)("div", _hoisted_5, [
                            (0, vue_2.createElementVNode)("div", _hoisted_6, [
                                (0, vue_2.createElementVNode)("a", _hoisted_7, [
                                    (0, vue_2.createElementVNode)("img", {
                                        class: "h-full w-full object-cover",
                                        src: require('../../images/information_page/SZGK.jpg'),
                                        alt: (0, vue_2.unref)(i18n_1.trans)('szgk', 'SZGK', 'information_page')
                                    }, null, 8, _hoisted_8),
                                    (0, vue_2.createElementVNode)("span", _hoisted_9, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("szgk", "SZGK", "information_page")), 1)
                                ])
                            ]),
                            (0, vue_2.createElementVNode)("div", _hoisted_10, [
                                (0, vue_2.createElementVNode)("a", _hoisted_11, [
                                    (0, vue_2.createElementVNode)("img", {
                                        class: "h-full w-full object-cover",
                                        src: require('../../images/information_page/MG.jpg'),
                                        alt: (0, vue_2.unref)(i18n_1.trans)('mg', 'MG', 'information_page')
                                    }, null, 8, _hoisted_12),
                                    (0, vue_2.createElementVNode)("span", _hoisted_13, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("mg", "MG", "information_page")), 1)
                                ])
                            ]),
                            (0, vue_2.createElementVNode)("div", _hoisted_14, [
                                (0, vue_2.createElementVNode)("a", _hoisted_15, [
                                    (0, vue_2.createElementVNode)("img", {
                                        class: "h-full w-full object-cover",
                                        src: require('../../images/information_page/TGK.jpg'),
                                        alt: (0, vue_2.unref)(i18n_1.trans)('tgk', 'TGK', 'information_page')
                                    }, null, 8, _hoisted_16),
                                    (0, vue_2.createElementVNode)("span", _hoisted_17, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("tgk", "TGK", "information_page")), 1)
                                ])
                            ]),
                            (0, vue_2.createElementVNode)("div", _hoisted_18, [
                                (0, vue_2.createElementVNode)("a", _hoisted_19, [
                                    (0, vue_2.createElementVNode)("img", {
                                        class: "h-full w-full object-cover",
                                        src: require('../../images/information_page/marketing.jpg'),
                                        alt: (0, vue_2.unref)(i18n_1.trans)('marketing', 'Marketing', 'information_page')
                                    }, null, 8, _hoisted_20),
                                    (0, vue_2.createElementVNode)("span", _hoisted_21, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("marketing", "Marketing", "information_page")), 1)
                                ])
                            ]),
                            (0, vue_2.createElementVNode)("div", _hoisted_22, [
                                (0, vue_2.createElementVNode)("a", _hoisted_23, [
                                    (0, vue_2.createElementVNode)("img", {
                                        class: "h-full w-full object-cover",
                                        src: require('../../images/information_page/egyeb.jpg'),
                                        alt: (0, vue_2.unref)(i18n_1.trans)('other', 'Other', 'information_page')
                                    }, null, 8, _hoisted_24),
                                    (0, vue_2.createElementVNode)("span", _hoisted_25, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("other", "Other", "information_page")), 1)
                                ])
                            ]),
                            (0, vue_2.createElementVNode)("div", _hoisted_26, [
                                (0, vue_2.createElementVNode)("a", _hoisted_27, [
                                    (0, vue_2.createElementVNode)("img", {
                                        class: "h-full w-full object-cover",
                                        src: require('../../images/information_page/shopsuli.png'),
                                        alt: (0, vue_2.unref)(i18n_1.trans)('shopsuli', 'Shopsuli', 'information_page')
                                    }, null, 8, _hoisted_28),
                                    (0, vue_2.createElementVNode)("span", _hoisted_29, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("shopsuli", "Shopsuli", "information_page")), 1)
                                ])
                            ])
                        ])
                    ]),
                    (0, vue_2.createElementVNode)("div", _hoisted_30, [
                        (0, vue_2.createElementVNode)("h3", _hoisted_31, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("updates", "Updates", "information_page")), 1),
                        ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)((0, vue_2.unref)(informationPageStore).updateBox, (item, index) => {
                            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                                class: (0, vue_2.normalizeClass)(["w-full mt-5", {
                                        'hidden': index > 4 && !showMoreUpdate.value,
                                    }])
                            }, [
                                (0, vue_2.createElementVNode)("div", _hoisted_32, [
                                    (0, vue_2.createElementVNode)("div", _hoisted_33, [
                                        (0, vue_2.createElementVNode)("div", _hoisted_34, (0, vue_2.toDisplayString)(item[0]), 1)
                                    ]),
                                    (0, vue_2.createElementVNode)("div", _hoisted_35, [
                                        (0, vue_2.createElementVNode)("div", _hoisted_36, (0, vue_2.toDisplayString)(item[4]), 1),
                                        (item[5] == 'created')
                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_37, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("created_at", "Created at", "information_page")) + ": " + (0, vue_2.toDisplayString)(item[2]), 1))
                                            : (0, vue_2.createCommentVNode)("", true),
                                        (item[5] == 'modified')
                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_38, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("modified_at", "Modified at", "information_page")) + ": " + (0, vue_2.toDisplayString)(item[2]), 1))
                                            : (0, vue_2.createCommentVNode)("", true)
                                    ])
                                ])
                            ], 2));
                        }), 256)),
                        (!showMoreUpdate.value)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("button", {
                                key: 0,
                                class: "btn btn-block bg-opacity-75 bg-gray-darkest text-white font-bold py-2 px-4 mt-5 rounded",
                                onClick: _cache[0] || (_cache[0] = ($event) => (showMoreUpdates()))
                            }, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("more", "More", "information_page")), 1))
                            : (0, vue_2.createCommentVNode)("", true),
                        (showMoreUpdate.value)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("button", {
                                key: 1,
                                class: "btn btn-block bg-opacity-75 bg-gray-darkest text-white font-bold py-2 px-4 mt-5 rounded",
                                onClick: _cache[1] || (_cache[1] = ($event) => (showLessUpdates()))
                            }, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("less", "Less", "information_page")), 1))
                            : (0, vue_2.createCommentVNode)("", true)
                    ])
                ])
            ]));
        };
    }
});
