"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const languages = [
    {
        locale: 'hu',
        titleEn: 'Hungarian',
        title: 'Magyar',
        flag: 'hu.svg'
    },
    {
        locale: 'en',
        titleEn: 'English',
        title: 'English',
        flag: 'uk.svg'
    },
    {
        locale: 'ro',
        titleEn: 'Romanian',
        title: 'Română',
        flag: 'ro.svg'
    },
    {
        locale: 'sr',
        titleEn: 'Serbian',
        title: 'Srpski',
        flag: 'sr.svg'
    }
];
exports.default = languages;
