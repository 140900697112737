"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpareWheelFilters = exports.MotorcycleTyreFilters = exports.ForkliftTyreFilters = exports.AgriculturalAndIndustrialTyreFilters = exports.TruckTyreFilters = exports.CarTyreFilters = exports.TyreFilters = exports.TubeProductFilters = exports.SkuProductFilters = exports.ValveProductFilters = exports.RimProductFilters = exports.AccessoriesProductFilters = exports.TapeProductFilters = exports.ProductFilters = exports.ProductFilter = void 0;
class ProductFilter {
    constructor(id, values = []) {
        this.id = id;
        this.values = values;
    }
}
exports.ProductFilter = ProductFilter;
class ProductFilters {
}
exports.ProductFilters = ProductFilters;
class TapeProductFiltersClass extends ProductFilters {
    constructor() {
        super(...arguments);
        this.type = new ProductFilter('type', ['tape']);
        this.axCategory = new ProductFilter('axCategory');
        this.brandCode = new ProductFilter('brandCode');
        this.width = new ProductFilter('width');
        this.diameter = new ProductFilter('diameter');
    }
}
exports.TapeProductFilters = new TapeProductFiltersClass();
class AccessoriesProductFiltersClass extends ProductFilters {
    constructor() {
        super(...arguments);
        this.axCategory = new ProductFilter('axCategory');
        this.diameter = new ProductFilter('diameter');
    }
}
exports.AccessoriesProductFilters = new AccessoriesProductFiltersClass();
class RimProductFiltersClass extends ProductFilters {
    constructor() {
        super(...arguments);
        this.type = new ProductFilter('type', ['rim']);
        this.brandCode = new ProductFilter('brandCode');
        this.width = new ProductFilter('width');
        this.diameter = new ProductFilter('diameter');
    }
}
class ValveProductFiltersClass extends ProductFilters {
    constructor() {
        super(...arguments);
        this.type = new ProductFilter('type', ['valve']);
    }
}
class SkuProductFiltersClass extends ProductFilters {
    constructor() {
        super(...arguments);
        this.id = new ProductFilter('id');
        this.ean = new ProductFilter('ean');
        this.manufacturerSku = new ProductFilter('manufacturerSku');
    }
}
exports.RimProductFilters = new RimProductFiltersClass();
exports.ValveProductFilters = new ValveProductFiltersClass();
exports.SkuProductFilters = new SkuProductFiltersClass();
class TubeProductFiltersClass extends ProductFilters {
    constructor() {
        super(...arguments);
        this.type = new ProductFilter('type', ['tube']);
        this.axCategory = new ProductFilter('axCategory');
        this.brandCode = new ProductFilter('brandCode');
        this.width = new ProductFilter('width');
        this.diameter = new ProductFilter('diameter');
        this.valveType = new ProductFilter('valveType');
    }
}
exports.TubeProductFilters = new TubeProductFiltersClass();
class TyreFiltersClass extends ProductFilters {
    constructor() {
        super(...arguments);
        this.axCategory = new ProductFilter('axCategory');
        this.width = new ProductFilter('width');
        this.profile = new ProductFilter('profile');
        this.diameter = new ProductFilter('diameter');
        this.brandCode = new ProductFilter('brandCode');
    }
}
exports.TyreFilters = new TyreFiltersClass();
class CarTyreFiltersClass extends TyreFiltersClass {
    constructor() {
        super(...arguments);
        this.type = new ProductFilter('type', ['carTyre']);
        this.speedIndex = new ProductFilter('speedIndex');
        this.loadIndex = new ProductFilter('loadIndex');
        this.homologatedNotation = new ProductFilter('homologatedNotation');
        this.fuelEfficiency = new ProductFilter('fuelEfficiency');
        this.wetGripClass = new ProductFilter('wetGripClass');
        this.noiseMeasurementClass = new ProductFilter('noiseMeasurementClass');
        this.runFlat = new ProductFilter('runFlat');
        this.isReinforcedVersion = new ProductFilter('isReinforcedVersion');
        this.isForElectricVehicle = new ProductFilter('isForElectricVehicle');
        this.isRimFringeProtector = new ProductFilter('isRimFringeProtector');
    }
}
exports.CarTyreFilters = new CarTyreFiltersClass();
class TruckTyreFiltersClass extends TyreFiltersClass {
    constructor() {
        super(...arguments);
        this.type = new ProductFilter('type', ['truckTyre']);
        this.applicationNotation = new ProductFilter('applicationNotation');
    }
}
exports.TruckTyreFilters = new TruckTyreFiltersClass();
class AgriculturalAndIndustrialTyreFiltersClass extends TyreFiltersClass {
}
exports.AgriculturalAndIndustrialTyreFilters = new AgriculturalAndIndustrialTyreFiltersClass();
class ForkliftTyreFiltersClass extends TyreFiltersClass {
}
exports.ForkliftTyreFilters = new ForkliftTyreFiltersClass();
class MotorcycleTyreFiltersClass extends TyreFiltersClass {
}
exports.MotorcycleTyreFilters = new MotorcycleTyreFiltersClass();
class SpareWheelFiltersClass extends TyreFiltersClass {
}
exports.SpareWheelFilters = new SpareWheelFiltersClass();
