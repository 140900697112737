"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _withScopeId = n => ((0, vue_2.pushScopeId)("data-v-3f7329c2"), n = n(), (0, vue_2.popScopeId)(), n);
const _hoisted_1 = {
    key: 0,
    class: "2xl:container mx-auto px-4 py-7 my-3 bg-white rounded-2xl"
};
const _hoisted_2 = { class: "text-center text-[34px] mb-3 font-bold" };
const _hoisted_3 = { class: "text-center mb-5" };
const _hoisted_4 = ["innerHTML"];
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { class: "font-bold text-2xl" };
const _hoisted_7 = { class: "pl-0" };
const _hoisted_8 = ["href"];
const vue_3 = require("vue");
const vuex_1 = require("vuex");
const vue_router_1 = require("vue-router");
const toastStore_1 = require("../../stores/toast/toastStore");
const i18n_1 = require("../../common/i18n");
exports.default = (0, vue_1.defineComponent)({
    __name: 'NewsArticle',
    setup(__props) {
        const store = (0, vuex_1.useStore)();
        const route = (0, vue_router_1.useRoute)();
        const toastStore = (0, toastStore_1.useToastStore)();
        const newsArticle = (0, vue_3.computed)(() => store.getters["news/article"]);
        const newsFiles = (0, vue_3.computed)(() => store.getters["newsFiles/newsFiles"]);
        (0, vue_3.onBeforeMount)(() => {
            store.dispatch("news/reset");
            store
                .dispatch("news/getArticle", route.params.id)
                .catch((error) => {
                toastStore.addErrorToast(error.toString());
            });
            store
                .dispatch("newsFiles/getNewsFiles", {
                pagination: false,
                news: route.params.id,
            })
                .catch((error) => {
                toastStore.addErrorToast(error.toString());
            });
        });
        return (_ctx, _cache) => {
            return ((0, vue_2.unref)(newsArticle))
                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                    (0, vue_2.createElementVNode)("div", _hoisted_2, (0, vue_2.toDisplayString)((0, vue_2.unref)(newsArticle).title), 1),
                    (0, vue_2.createElementVNode)("div", _hoisted_3, (0, vue_2.toDisplayString)((0, vue_2.unref)(newsArticle).createdDatetime), 1),
                    (0, vue_2.createElementVNode)("article", {
                        class: "ql-editor",
                        innerHTML: (0, vue_2.unref)(newsArticle).body
                    }, null, 8, _hoisted_4),
                    ((0, vue_2.unref)(newsFiles).length > 0)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_5, [
                            (0, vue_2.createElementVNode)("div", _hoisted_6, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("news_related_files", "News related files", "news_article")), 1),
                            (0, vue_2.createElementVNode)("ul", _hoisted_7, [
                                ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)((0, vue_2.unref)(newsFiles), (newsFile) => {
                                    return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("li", {
                                        key: newsFile.id,
                                        class: "my-2"
                                    }, [
                                        (0, vue_2.createElementVNode)("a", {
                                            target: "_blank",
                                            href: newsFile.contentUrl
                                        }, (0, vue_2.toDisplayString)(newsFile.originalFileName), 9, _hoisted_8)
                                    ]));
                                }), 128))
                            ])
                        ]))
                        : (0, vue_2.createCommentVNode)("", true)
                ]))
                : (0, vue_2.createCommentVNode)("", true);
        };
    }
});
