"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewslettersState = void 0;
class NewslettersState {
    constructor({ loading, newsletters, totalNewsletters, highlightedNewsletters }) {
        this.loading = loading;
        this.newsletters = newsletters;
        this.totalNewsletters = totalNewsletters;
        this.highlightedNewsletters = highlightedNewsletters;
    }
    static factory() {
        return new NewslettersState({
            loading: false,
            newsletters: [],
            totalNewsletters: 0,
            highlightedNewsletters: []
        });
    }
}
exports.NewslettersState = NewslettersState;
