"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainUseAreaEnum = void 0;
var MainUseAreaEnum;
(function (MainUseAreaEnum) {
    MainUseAreaEnum[MainUseAreaEnum["UNKNOWN"] = 0] = "UNKNOWN";
    MainUseAreaEnum[MainUseAreaEnum["PUBLIC_ROAD"] = 1] = "PUBLIC_ROAD";
    MainUseAreaEnum[MainUseAreaEnum["BUILDING_AREA"] = 2] = "BUILDING_AREA";
    MainUseAreaEnum[MainUseAreaEnum["AGRICULTURAL_AREA"] = 3] = "AGRICULTURAL_AREA";
    MainUseAreaEnum[MainUseAreaEnum["SANDY_ROAD"] = 4] = "SANDY_ROAD";
    MainUseAreaEnum[MainUseAreaEnum["STONED_ROAD"] = 5] = "STONED_ROAD";
    MainUseAreaEnum[MainUseAreaEnum["FOREST_AREA"] = 6] = "FOREST_AREA";
    MainUseAreaEnum[MainUseAreaEnum["OTHER"] = 7] = "OTHER";
})(MainUseAreaEnum = exports.MainUseAreaEnum || (exports.MainUseAreaEnum = {}));
