"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderInvoiceCreateRequest = void 0;
const validators_1 = require("../../common/utils/validators");
class OrderInvoiceCreateRequest {
    constructor(orderId = '', invoiceId = '', grossTotal = 0, paymentMode = 0, note = '', uploaderEmail = '', transferName = null, transferPhone = null, transferEmail = null, originalFilename = '', base64EncodedFile = '') {
        this.orderId = orderId;
        this.invoiceId = invoiceId;
        this.grossTotal = grossTotal;
        this.paymentMode = paymentMode;
        this.note = note;
        this.uploaderEmail = uploaderEmail;
        this.transferName = transferName;
        this.transferPhone = transferPhone;
        this.transferEmail = transferEmail;
        this.originalFilename = originalFilename;
        this.base64EncodedFile = base64EncodedFile;
    }
    isValidGrossTotal(netAmount) {
        return netAmount / 2 < this.grossTotal && this.grossTotal < netAmount * 2;
    }
    isValid(netAmount) {
        return !(0, validators_1.isBlank)(this.invoiceId)
            && this.isValidGrossTotal(netAmount)
            && !(0, validators_1.isBlank)(this.transferName)
            && !(0, validators_1.isBlank)(this.transferPhone)
            && !(0, validators_1.isBlank)(this.transferEmail)
            && (0, validators_1.isValidEmail)(this.transferEmail)
            && !(0, validators_1.isBlank)(this.base64EncodedFile)
            && this.paymentMode !== 0;
    }
}
exports.OrderInvoiceCreateRequest = OrderInvoiceCreateRequest;
