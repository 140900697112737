"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrdersState = void 0;
class OrdersState {
    constructor({ orders, orderItems, orderDeliveryInfos }) {
        this.orders = orders;
        this.orderItems = orderItems;
        this.orderDeliveryInfos = orderDeliveryInfos;
    }
    static factory() {
        return new OrdersState({
            orders: [],
            orderItems: null,
            orderDeliveryInfos: []
        });
    }
}
exports.OrdersState = OrdersState;
