"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoicesState = void 0;
class InvoicesState {
    constructor({ invoices, invoiceItems }) {
        this.invoices = invoices;
        this.invoiceItems = invoiceItems;
    }
    static factory() {
        return new InvoicesState({
            invoices: [],
            invoiceItems: []
        });
    }
}
exports.InvoicesState = InvoicesState;
