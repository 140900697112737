"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BannerFileTypeEnum = void 0;
var BannerFileTypeEnum;
(function (BannerFileTypeEnum) {
    BannerFileTypeEnum["MAIN_PAGE"] = "main";
    BannerFileTypeEnum["MAIN_PAGE_MOBILE"] = "main_mobile";
    BannerFileTypeEnum["SEARCH_PAGE"] = "search";
    BannerFileTypeEnum["SEARCH_PAGE_MOBILE"] = "search_mobile";
    BannerFileTypeEnum["UNIVERSAL"] = "univ";
})(BannerFileTypeEnum = exports.BannerFileTypeEnum || (exports.BannerFileTypeEnum = {}));
