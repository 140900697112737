"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductFilterState = void 0;
const ProductFilter_1 = require("../../../models/ProductFilter");
class ProductFilterState {
    constructor({ loading, filterValues }) {
        this.loading = loading;
        this.filterValues = filterValues;
    }
    static factory(filterValues = new ProductFilter_1.ProductFilters()) {
        return new ProductFilterState({ loading: false, filterValues });
    }
}
exports.ProductFilterState = ProductFilterState;
