"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NEWSLETTER_CATEGORIES = exports.COMPANY_NEWSLETTER_CATEGORY = exports.MG_NEWSLETTER_CATEGORY = exports.TGK_NEWSLETTER_CATEGORY = exports.SZGK_NEWSLETTER_CATEGORY = void 0;
exports.SZGK_NEWSLETTER_CATEGORY = "szgk";
exports.TGK_NEWSLETTER_CATEGORY = "tgk";
exports.MG_NEWSLETTER_CATEGORY = "mg";
exports.COMPANY_NEWSLETTER_CATEGORY = "company";
exports.NEWSLETTER_CATEGORIES = [
    exports.SZGK_NEWSLETTER_CATEGORY,
    exports.TGK_NEWSLETTER_CATEGORY,
    exports.MG_NEWSLETTER_CATEGORY,
    exports.COMPANY_NEWSLETTER_CATEGORY
];
