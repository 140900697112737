"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const shop_1 = require("../../common/utils/shop");
const validators_1 = require("../../common/utils/validators");
class CarcassReturnRequest {
    constructor(createdUser = '', //IRI
    createdDateTime = (0, shop_1.formatDate)(new Date()), quantity, size, originalFilename = null, base64EncodedFile = null, emails = []) {
        this.createdUser = createdUser;
        this.createdDateTime = createdDateTime;
        this.quantity = quantity;
        this.size = size;
        this.originalFilename = originalFilename;
        this.base64EncodedFile = base64EncodedFile;
        this.emails = emails;
    }
    isSizeValid() {
        return !(0, validators_1.isBlank)(this.size);
    }
    isQuantityValid() {
        return !(0, validators_1.isBlank)(this.quantity);
    }
}
exports.default = CarcassReturnRequest;
