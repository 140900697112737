"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "flex items-center p-4" };
const _hoisted_2 = ["value"];
const vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'PricingOptionRadio',
    props: {
        modelValue: {
            type: [String, null],
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
    emits: ["update:modelValue"],
    setup(__props, { emit }) {
        const props = __props;
        const internalValue = (0, vue_3.ref)(props.modelValue);
        (0, vue_3.watch)(internalValue, (newValue) => {
            emit("update:modelValue", newValue);
        });
        (0, vue_3.watch)(() => props.modelValue, (newValue) => {
            internalValue.value = newValue;
        });
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("label", _hoisted_1, [
                (0, vue_2.withDirectives)((0, vue_2.createElementVNode)("input", {
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((internalValue).value = $event)),
                    class: "w-6 h-6 mr-2 accent-blue",
                    type: "radio",
                    value: __props.value
                }, null, 8, _hoisted_2), [
                    [vue_2.vModelRadio, internalValue.value]
                ]),
                (0, vue_2.createElementVNode)("span", null, (0, vue_2.toDisplayString)(__props.label), 1)
            ]));
        };
    }
});
