<template>
    <button
        id="back-to-top-button"
        class="fixed block w-12 h-12 right-4 bottom-4 bg-black opacity-0 -z-10 border-0 rounded shadow text-2xl"
        :class="{visible: isVisible}"
        @click="backToTop"
    >
        <i
            class="marso-icon-chevron-up text-gray"
            aria-hidden="true"
        ></i>
    </button>
</template>

<script>
import { defineComponent, nextTick, onMounted, ref } from 'vue';

export default defineComponent({
    name: "BackToTop",
    setup() {
        const isVisible = ref(false);

        onMounted(() => {
            nextTick(() => {
                document.addEventListener("scroll", () => {
                    isVisible.value =
                        Math.max(
                            document.body.scrollTop,
                            document.documentElement.scrollTop
                        ) > 250;
                });
            });
        });


        const backToTop = function() {
            // enable this, when safari will support scroll-behavior
            //
            // document.body.scrollTop = document.documentElement.scrollTop = 0;

            //
            // below is the CPU heavy version of scroll animation just
            // for bitchy safari browser
            //

            const duration = 400, element = document.scrollingElement;

            // cancel if already on target position
            if (element.scrollTop === 0) {
                return;
            }

            const cosParameter = (element.scrollTop - 0) / 2;
            let scrollCount = 0,
                oldTimestamp = null;

            const step = newTimestamp => {
                if (oldTimestamp !== null) {
                    // if duration is 0 scrollCount will be Infinity
                    scrollCount += (Math.PI * (newTimestamp - oldTimestamp)) / duration;

                    if (scrollCount >= Math.PI) {
                        return (element.scrollTop = 0);
                    }

                    element.scrollTop = cosParameter + cosParameter * Math.cos(scrollCount);
                }
                oldTimestamp = newTimestamp;
                window.requestAnimationFrame(step);
            }

            window.requestAnimationFrame(step);
        };

        return {
            isVisible,
            backToTop
        };
    }
});
</script>

<style lang="scss">
// enable this, when safari will support scroll-behavior
//
// html,
// body {
//     scroll-behavior: smooth;
// }
</style>

<style lang="scss" scoped>
#back-to-top-button {
    &.visible {
        @apply opacity-80 z-10 transition-opacity duration-300 ease-in;

        &:hover {
            @apply opacity-100;
        }
    }
}
</style>
