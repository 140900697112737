"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderNoteState = void 0;
class OrderNoteState {
    constructor({ loading, orderNote }) {
        this.loading = loading;
        this.orderNote = orderNote;
    }
    static factory() {
        return new OrderNoteState({
            loading: false,
            orderNote: null
        });
    }
}
exports.OrderNoteState = OrderNoteState;
