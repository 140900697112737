"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const datetimeFormats = {
    'en-US': {
        stamp: {
            year: 'numeric', month: 'numeric', day: 'numeric'
        },
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
        },
        iso: {
            year: 'numeric', month: 'numeric', day: 'numeric',
            hour: 'numeric', minute: 'numeric', second: 'numeric'
        }
    },
    'hu-HU': {
        stamp: {
            year: 'numeric', month: 'numeric', day: 'numeric'
        },
        short: {
            year: 'numeric', month: 'numeric', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        },
        iso: {
            year: 'numeric', month: 'numeric', day: 'numeric',
            hour: 'numeric', minute: 'numeric', second: 'numeric'
        }
    }
};
exports.default = datetimeFormats;
