"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "w-full flex flex-col justify-center items-center gap-10" };
const _hoisted_2 = { class: "flex items-center justify-center p-8" };
const _hoisted_3 = ["src"];
const _hoisted_4 = { class: "w-full flex flex-col justify-center items-center lg:flex-row lg:items-start gap-10" };
const _hoisted_5 = { class: "flex-col content-center text-center w-96 max-w-full px-4" };
const _hoisted_6 = { class: "p-4 mb-2 w-full border-b-1 border-gray-dark text-gray-darker font-bold" };
const _hoisted_7 = { class: "flex-col content-center text-center w-96 max-w-full px-4" };
const _hoisted_8 = { class: "p-4 mb-2 w-full border-b-1 border-gray-dark text-gray-darker font-bold" };
const _hoisted_9 = { class: "flex-col content-center text-center w-96 max-w-full px-4" };
const _hoisted_10 = { class: "p-4 mb-2 w-full border-b-1 border-gray-dark text-gray-darker font-bold" };
const _hoisted_11 = { class: "text-right flex justify-end" };
const vue_3 = require("vue");
const vue_router_1 = require("vue-router");
const securityStore_1 = require("../../stores/security/securityStore");
const toastStore_1 = require("../../stores/toast/toastStore");
const i18n_1 = require("../../common/i18n");
const validators_1 = require("../../common/utils/validators");
const Form_1 = require("../../components/Form");
exports.default = (0, vue_1.defineComponent)({
    __name: 'RegistrationPage',
    setup(__props) {
        const router = (0, vue_router_1.useRouter)();
        const securityStore = (0, securityStore_1.useSecurityStore)();
        const toastStore = (0, toastStore_1.useToastStore)();
        const name = (0, vue_3.ref)("");
        const phone = (0, vue_3.ref)("");
        const email = (0, vue_3.ref)("");
        const company = (0, vue_3.ref)("");
        const tax = (0, vue_3.ref)("");
        const registry = (0, vue_3.ref)("");
        const bankAccountNumber = (0, vue_3.ref)("");
        const billingName = (0, vue_3.ref)("");
        const zipCode = (0, vue_3.ref)("");
        const city = (0, vue_3.ref)("");
        const address = (0, vue_3.ref)("");
        const handleSubmit = () => {
            try {
                const registrationData = {
                    name: name.value,
                    phone: phone.value,
                    email: email.value,
                    company: company.value,
                    tax: tax.value,
                    registry: registry.value,
                    bankAccountNumber: bankAccountNumber.value,
                    billingName: billingName.value,
                    zipCode: zipCode.value,
                    city: city.value,
                    address: address.value,
                };
                if (Object.values(registrationData).some((value) => !value)) {
                    toastStore.addErrorToast((0, i18n_1.trans)("all_fields_must_be_filled_in", "All fields must be filled in!", "registration"));
                    return;
                }
                securityStore.registration(router, registrationData).then(() => {
                    toastStore.addToast({
                        message: (0, i18n_1.trans)("successful_registration", "Successful registration!", "registration"),
                        type: "success",
                        timer: 5,
                    });
                    redirectToLogin();
                });
            }
            catch (error) {
                toastStore.addErrorToast(error);
            }
        };
        const redirectToLogin = () => {
            router.push({ name: "app_login" });
        };
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("h1", _hoisted_2, [
                    (0, vue_2.createElementVNode)("img", {
                        src: require('../../images/marso-logo-fekvo-red.svg'),
                        alt: "MARSO",
                        class: "w-60"
                    }, null, 8, _hoisted_3)
                ]),
                (0, vue_2.createElementVNode)("div", _hoisted_4, [
                    (0, vue_2.createElementVNode)("div", _hoisted_5, [
                        (0, vue_2.createElementVNode)("h2", _hoisted_6, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("contact_person", "Contact Person", "registration")), 1),
                        (0, vue_2.createVNode)((0, vue_2.unref)(Form_1.ShopInput), {
                            modelValue: name.value,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((name).value = $event)),
                            type: "text",
                            name: "name",
                            placeholder: (0, vue_2.unref)(i18n_1.trans)('name', 'Name', 'registration'),
                            autofocus: "",
                            class: "my-5",
                            required: true,
                            border: "",
                            "disable-focus-ring": "",
                            "show-validity": true,
                            valid: !(0, vue_2.unref)(validators_1.isBlank)(name.value)
                        }, null, 8, ["modelValue", "placeholder", "valid"]),
                        (0, vue_2.createVNode)((0, vue_2.unref)(Form_1.ShopInput), {
                            modelValue: phone.value,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((phone).value = $event)),
                            type: "tel",
                            name: "phone",
                            placeholder: (0, vue_2.unref)(i18n_1.trans)('mobile', 'Mobile', 'registration'),
                            autofocus: "",
                            class: "my-5",
                            required: true,
                            border: "",
                            "disable-focus-ring": "",
                            "show-validity": true,
                            valid: !(0, vue_2.unref)(validators_1.isBlank)(phone.value)
                        }, null, 8, ["modelValue", "placeholder", "valid"]),
                        (0, vue_2.createVNode)((0, vue_2.unref)(Form_1.ShopInput), {
                            modelValue: email.value,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((email).value = $event)),
                            type: "email",
                            name: "email",
                            placeholder: (0, vue_2.unref)(i18n_1.trans)('email', 'E-mail', 'registration'),
                            autofocus: "",
                            class: "my-5",
                            required: true,
                            border: "",
                            "disable-focus-ring": "",
                            "show-validity": true,
                            valid: (0, vue_2.unref)(validators_1.isValidEmail)(email.value)
                        }, null, 8, ["modelValue", "placeholder", "valid"])
                    ]),
                    (0, vue_2.createElementVNode)("div", _hoisted_7, [
                        (0, vue_2.createElementVNode)("h2", _hoisted_8, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("company_information", "Company Information", "registration")), 1),
                        (0, vue_2.createVNode)((0, vue_2.unref)(Form_1.ShopInput), {
                            modelValue: company.value,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((company).value = $event)),
                            type: "text",
                            name: "company",
                            placeholder: (0, vue_2.unref)(i18n_1.trans)('company_name', 'Company Name', 'registration'),
                            autofocus: "",
                            class: "my-5",
                            required: true,
                            border: "",
                            "disable-focus-ring": "",
                            "show-validity": true,
                            valid: !(0, vue_2.unref)(validators_1.isBlank)(company.value)
                        }, null, 8, ["modelValue", "placeholder", "valid"]),
                        (0, vue_2.createVNode)((0, vue_2.unref)(Form_1.ShopInput), {
                            modelValue: tax.value,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((tax).value = $event)),
                            type: "text",
                            name: "tax",
                            placeholder: (0, vue_2.unref)(i18n_1.trans)('tax_number', 'Tax Number', 'registration'),
                            autofocus: "",
                            class: "my-5",
                            required: true,
                            border: "",
                            "disable-focus-ring": "",
                            "show-validity": true,
                            valid: !(0, vue_2.unref)(validators_1.isBlank)(tax.value)
                        }, null, 8, ["modelValue", "placeholder", "valid"]),
                        (0, vue_2.createVNode)((0, vue_2.unref)(Form_1.ShopInput), {
                            modelValue: registry.value,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((registry).value = $event)),
                            type: "text",
                            name: "registry",
                            placeholder: (0, vue_2.unref)(i18n_1.trans)('company_registration_number', 'Company Registration Number', 'registration'),
                            autofocus: "",
                            class: "my-5",
                            required: true,
                            border: "",
                            "disable-focus-ring": "",
                            "show-validity": true,
                            valid: !(0, vue_2.unref)(validators_1.isBlank)(registry.value)
                        }, null, 8, ["modelValue", "placeholder", "valid"]),
                        (0, vue_2.createVNode)((0, vue_2.unref)(Form_1.ShopInput), {
                            modelValue: bankAccountNumber.value,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((bankAccountNumber).value = $event)),
                            type: "text",
                            name: "bankAccountNumber",
                            placeholder: (0, vue_2.unref)(i18n_1.trans)('bank_account_number', 'Bank Account Number', 'registration'),
                            autofocus: "",
                            class: "my-5",
                            required: true,
                            border: "",
                            "disable-focus-ring": "",
                            "show-validity": true,
                            valid: !(0, vue_2.unref)(validators_1.isBlank)(bankAccountNumber.value)
                        }, null, 8, ["modelValue", "placeholder", "valid"])
                    ]),
                    (0, vue_2.createElementVNode)("div", _hoisted_9, [
                        (0, vue_2.createElementVNode)("h2", _hoisted_10, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("billing_information", "Billing Information", "registration")), 1),
                        (0, vue_2.createVNode)((0, vue_2.unref)(Form_1.ShopInput), {
                            modelValue: billingName.value,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event) => ((billingName).value = $event)),
                            type: "text",
                            name: "billingName",
                            placeholder: (0, vue_2.unref)(i18n_1.trans)('billing_name', 'Billing Name', 'registration'),
                            autofocus: "",
                            class: "my-5",
                            required: true,
                            border: "",
                            "disable-focus-ring": "",
                            "show-validity": true,
                            valid: !(0, vue_2.unref)(validators_1.isBlank)(billingName.value)
                        }, null, 8, ["modelValue", "placeholder", "valid"]),
                        (0, vue_2.createVNode)((0, vue_2.unref)(Form_1.ShopInput), {
                            modelValue: zipCode.value,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event) => ((zipCode).value = $event)),
                            type: "text",
                            name: "zipCode",
                            placeholder: (0, vue_2.unref)(i18n_1.trans)('zip_code', 'Zip Code', 'registration'),
                            autofocus: "",
                            class: "my-5",
                            required: true,
                            border: "",
                            "disable-focus-ring": "",
                            "show-validity": true,
                            valid: !(0, vue_2.unref)(validators_1.isBlank)(zipCode.value)
                        }, null, 8, ["modelValue", "placeholder", "valid"]),
                        (0, vue_2.createVNode)((0, vue_2.unref)(Form_1.ShopInput), {
                            modelValue: city.value,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event) => ((city).value = $event)),
                            type: "text",
                            name: "city",
                            placeholder: (0, vue_2.unref)(i18n_1.trans)('city', 'City', 'registration'),
                            autofocus: "",
                            class: "my-5",
                            required: true,
                            border: "",
                            "disable-focus-ring": "",
                            "show-validity": true,
                            valid: !(0, vue_2.unref)(validators_1.isBlank)(city.value)
                        }, null, 8, ["modelValue", "placeholder", "valid"]),
                        (0, vue_2.createVNode)((0, vue_2.unref)(Form_1.ShopInput), {
                            modelValue: address.value,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event) => ((address).value = $event)),
                            type: "text",
                            name: "address",
                            placeholder: (0, vue_2.unref)(i18n_1.trans)('billing_address', 'Billing Address', 'registration'),
                            autofocus: "",
                            class: "my-5",
                            required: true,
                            border: "",
                            "disable-focus-ring": "",
                            "show-validity": true,
                            valid: !(0, vue_2.unref)(validators_1.isBlank)(address.value)
                        }, null, 8, ["modelValue", "placeholder", "valid"])
                    ])
                ]),
                (0, vue_2.createElementVNode)("button", {
                    type: "submit",
                    class: "w-auto text-white uppercase font-bold text-lg bg-red rounded-md py-2 px-8",
                    onClick: handleSubmit
                }, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("registration", "Registration", "registration")), 1),
                (0, vue_2.createElementVNode)("div", _hoisted_11, [
                    (0, vue_2.createElementVNode)("span", {
                        class: "cursor-pointer mb-12",
                        onClick: redirectToLogin
                    }, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("sign_in", "Sign in", "registration")), 1)
                ])
            ]));
        };
    }
});
