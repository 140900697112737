"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class UserRequest {
    constructor(plainPassword = null, email = '', customer = '', //IRI
    defaultAddress = null, groups = [], department = '', extraEmails = [], isActive = true) {
        this.plainPassword = plainPassword;
        this.email = email;
        this.customer = customer;
        this.defaultAddress = defaultAddress;
        this.groups = groups;
        this.department = department;
        this.extraEmails = extraEmails;
        this.isActive = isActive;
    }
}
exports.default = UserRequest;
