"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PRODUCT_STOCK_DETAILS_HIDE = exports.PRODUCT_STOCK_DETAILS_SHOW = exports.PRODUCT_STOCK_DETAILS_ADD = exports.PRODUCT_STOCK_DETAILS_LOADING = exports.PRODUCT_LIST_CHANGE = exports.PRODUCT_LIST_LOADING = exports.PRODUCT_LIST_RESET = void 0;
exports.PRODUCT_LIST_RESET = 'PRODUCT_LIST_RESET';
exports.PRODUCT_LIST_LOADING = 'PRODUCT_LIST_LOADING';
exports.PRODUCT_LIST_CHANGE = 'PRODUCT_LIST_CHANGE';
exports.PRODUCT_STOCK_DETAILS_LOADING = 'PRODUCT_STOCK_DETAILS_LOADING';
exports.PRODUCT_STOCK_DETAILS_ADD = 'PRODUCT_STOCK_DETAILS_ADD';
exports.PRODUCT_STOCK_DETAILS_SHOW = 'PRODUCT_STOCK_DETAILS_SHOW';
exports.PRODUCT_STOCK_DETAILS_HIDE = 'PRODUCT_STOCK_DETAILS_HIDE';
