"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PricingTubesAndTapesCategoryEnum = exports.PricingRimCategoryEnum = exports.PricingTruckTyreTypeEnum = exports.PricingPassengerTyreSeasonEnum = exports.PricingTyreCategoryEnum = exports.PricingTypeEnum = exports.PricingStatusEnum = void 0;
/* eslint-disable no-unused-vars */
var PricingStatusEnum;
(function (PricingStatusEnum) {
    PricingStatusEnum["ACTIVE"] = "ACTIVE";
    PricingStatusEnum["INACTIVE"] = "INACTIVE";
})(PricingStatusEnum = exports.PricingStatusEnum || (exports.PricingStatusEnum = {}));
var PricingTypeEnum;
(function (PricingTypeEnum) {
    PricingTypeEnum["PERCENT"] = "PERCENT";
    PricingTypeEnum["FIXED"] = "FIXED";
})(PricingTypeEnum = exports.PricingTypeEnum || (exports.PricingTypeEnum = {}));
var PricingTyreCategoryEnum;
(function (PricingTyreCategoryEnum) {
    PricingTyreCategoryEnum["PASSENGER_TYRES"] = "PASSENGER_TYRES";
    PricingTyreCategoryEnum["TRUCK_TYRES"] = "TRUCK_TYRES";
    PricingTyreCategoryEnum["AGRICULTURAL_AND_INDUSTRIAL_TYRES"] = "AGRICULTURAL_AND_INDUSTRIAL_TYRES";
    PricingTyreCategoryEnum["FORKLIFT_TYRES"] = "FORKLIFT_TYRES";
    PricingTyreCategoryEnum["MOTORCYCLE_TYRES"] = "MOTORCYCLE_TYRES";
    PricingTyreCategoryEnum["SPARE_WHEELS"] = "SPARE_WHEELS";
    PricingTyreCategoryEnum["RIMS"] = "RIMS";
    PricingTyreCategoryEnum["TUBES_AND_TAPES"] = "TUBES_AND_TAPES";
    // ACCESSORIES = "ACCESSORIES"
})(PricingTyreCategoryEnum = exports.PricingTyreCategoryEnum || (exports.PricingTyreCategoryEnum = {}));
var PricingPassengerTyreSeasonEnum;
(function (PricingPassengerTyreSeasonEnum) {
    PricingPassengerTyreSeasonEnum["SUMMER_TYRE"] = "SUMMER_TYRE";
    PricingPassengerTyreSeasonEnum["WINTER_TYRE"] = "WINTER_TYRE";
    PricingPassengerTyreSeasonEnum["ALL_SEASON_TYRE"] = "ALL_SEASON_TYRE";
})(PricingPassengerTyreSeasonEnum = exports.PricingPassengerTyreSeasonEnum || (exports.PricingPassengerTyreSeasonEnum = {}));
var PricingTruckTyreTypeEnum;
(function (PricingTruckTyreTypeEnum) {
    PricingTruckTyreTypeEnum["TUBELESS_TYRE"] = "TUBELESS_TYRE";
    PricingTruckTyreTypeEnum["_20_INCHES_TYRE"] = "_20_INCHES_TYRE";
    PricingTruckTyreTypeEnum["RETREADED_TYRE"] = "RETREADED_TYRE";
})(PricingTruckTyreTypeEnum = exports.PricingTruckTyreTypeEnum || (exports.PricingTruckTyreTypeEnum = {}));
var PricingRimCategoryEnum;
(function (PricingRimCategoryEnum) {
    PricingRimCategoryEnum["ALLOY_RIM"] = "ALLOY_RIM";
    PricingRimCategoryEnum["STEEL_RIM"] = "STEEL_RIM";
    // FORKLIFT = "FORKLIFT",
    PricingRimCategoryEnum["TWIN_WHEELS"] = "TWIN_WHEELS";
    PricingRimCategoryEnum["AGRICULTURAL_RIM"] = "AGRICULTURAL_RIM";
    // VALVE = "VALVE"
})(PricingRimCategoryEnum = exports.PricingRimCategoryEnum || (exports.PricingRimCategoryEnum = {}));
var PricingTubesAndTapesCategoryEnum;
(function (PricingTubesAndTapesCategoryEnum) {
    PricingTubesAndTapesCategoryEnum["AGRICULTURAL_TUBES"] = "AGRICULTURAL_TUBES";
    PricingTubesAndTapesCategoryEnum["TRUCK_TUBES"] = "TRUCK_TUBES";
    PricingTubesAndTapesCategoryEnum["VAN_TUBES"] = "VAN_TUBES";
    PricingTubesAndTapesCategoryEnum["PASSENGER_TUBES"] = "PASSENGER_TUBES";
    PricingTubesAndTapesCategoryEnum["FORKLIFT_TUBES"] = "FORKLIFT_TUBES";
    PricingTubesAndTapesCategoryEnum["WORK_MACHINE_TUBES"] = "WORK_MACHINE_TUBES";
    PricingTubesAndTapesCategoryEnum["MOTORCYCLE_TUBES"] = "MOTORCYCLE_TUBES";
    PricingTubesAndTapesCategoryEnum["BICYCLE_TUBES"] = "BICYCLE_TUBES";
    PricingTubesAndTapesCategoryEnum["AGRICULTURAL_TAPES"] = "AGRICULTURAL_TAPES";
    PricingTubesAndTapesCategoryEnum["TRUCK_TAPES"] = "TRUCK_TAPES";
    PricingTubesAndTapesCategoryEnum["FORKLIFT_TAPES"] = "FORKLIFT_TAPES";
    PricingTubesAndTapesCategoryEnum["WORK_MACHINE_TAPES"] = "WORK_MACHINE_TAPES";
    PricingTubesAndTapesCategoryEnum["MOTORCYCLE_TAPES"] = "MOTORCYCLE_TAPES";
})(PricingTubesAndTapesCategoryEnum = exports.PricingTubesAndTapesCategoryEnum || (exports.PricingTubesAndTapesCategoryEnum = {}));
// export enum PricingAccessoryCategoryEnum {
//     SNOW_CHAINS = "SNOW_CHAINS",
//     WINDSHIELD_WIPER = "WINDSHIELD_WIPER",
//     HUB_CAP = "HUB_CAP",
//     CAR_CARE_PRODUCTS = "CAR_CARE_PRODUCTS",
//     OTHER_ACCESSORIES = "OTHER_ACCESSORIES",
// }
