"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "bg-white shadow p-0" };
const _hoisted_2 = {
    key: 0,
    class: "p-4"
};
const _hoisted_3 = ["src", "alt"];
const _hoisted_4 = { class: "p-4" };
const _hoisted_5 = { class: "mt-2 block" };
const _hoisted_6 = { class: "bg-gray flex pr-4 items-center" };
const _hoisted_7 = { class: "flex-none p-1 uppercase bg-[#222] text-white font-bold mr-2 text-[12px]" };
const _hoisted_8 = { key: 0 };
const _hoisted_9 = { key: 1 };
const _hoisted_10 = { key: 2 };
const _hoisted_11 = { key: 3 };
const _hoisted_12 = { key: 4 };
const _hoisted_13 = { class: "flex-none font-light p-1 text-[14px]" };
const News_1 = require("../../models/News");
const i18n_1 = require("../../common/i18n");
exports.default = (0, vue_1.defineComponent)({
    __name: 'NewsCard',
    props: {
        news: {
            type: Object,
            required: true,
        },
    },
    setup(__props) {
        return (_ctx, _cache) => {
            const _component_router_link = (0, vue_2.resolveComponent)("router-link");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (__props.news !== undefined && __props.news !== null && __props.news.fileUrl !== '')
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_2, [
                        (0, vue_2.createElementVNode)("img", {
                            src: __props.news.fileUrl,
                            alt: __props.news.originalFileName,
                            class: "object-cover w-full h-52"
                        }, null, 8, _hoisted_3)
                    ]))
                    : (0, vue_2.createCommentVNode)("", true),
                (0, vue_2.createElementVNode)("div", _hoisted_4, [
                    (0, vue_2.createVNode)(_component_router_link, {
                        to: { name: 'news_article', params: { id: __props.news.id } },
                        class: "block text-2xl text-gray-darker font-bold hover:text-gray-dark"
                    }, {
                        default: (0, vue_2.withCtx)(() => [
                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(__props.news.title), 1)
                        ]),
                        _: 1
                    }, 8, ["to"]),
                    (0, vue_2.createElementVNode)("p", _hoisted_5, (0, vue_2.toDisplayString)(__props.news.description), 1)
                ]),
                (0, vue_2.createElementVNode)("div", _hoisted_6, [
                    (0, vue_2.createElementVNode)("div", _hoisted_7, [
                        (__props.news.category.includes((0, vue_2.unref)(News_1.TGK_NEWS_CATEGORY)) && __props.news.category.includes((0, vue_2.unref)(News_1.MG_NEWS_CATEGORY)))
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_8, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("tgk_mg_news", "TGK/MG", "news_card")), 1))
                            : (__props.news.category.includes((0, vue_2.unref)(News_1.SZGK_NEWS_CATEGORY)))
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_9, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("szgk_news", "SZGK", "news_card")), 1))
                                : (__props.news.category.includes((0, vue_2.unref)(News_1.TGK_NEWS_CATEGORY)))
                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_10, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("tgk_news", "TGK", "news_card")), 1))
                                    : (__props.news.category.includes((0, vue_2.unref)(News_1.MG_NEWS_CATEGORY)))
                                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_11, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("mg_news", "MG", "news_card")), 1))
                                        : (__props.news.category.includes((0, vue_2.unref)(News_1.COMPANY_NEWS_CATEGORY)))
                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_12, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("company_news", "Company", "news_card")), 1))
                                            : (0, vue_2.createCommentVNode)("", true)
                    ]),
                    (0, vue_2.createElementVNode)("div", _hoisted_13, (0, vue_2.toDisplayString)(__props.news.createdDatetime), 1),
                    (0, vue_2.createVNode)(_component_router_link, {
                        to: { name: 'news_article', params: { id: __props.news.id } },
                        class: "block flex-grow hover:underline text-right text-[12px] font-bold"
                    }, {
                        default: (0, vue_2.withCtx)(() => [
                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)("visit_article", "Visit Article", "news_card")), 1)
                        ]),
                        _: 1
                    }, 8, ["to"])
                ])
            ]));
        };
    }
});
