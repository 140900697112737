"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Filters {
    constructor() {
        this.page = 1;
        this.workingLanguages = [];
        this.onlyEmpty = false;
        this.searchParameter = 'key';
        this.searchString = '';
        this.orderBy = 'key';
        this.orderDirection = 'asc';
        this.itemsPerPage = 10;
    }
    createQuery() {
        let query = {};
        query.page = this.page;
        query.order = [];
        query.order[this.orderBy] = this.orderDirection;
        query.itemsPerPage = this.itemsPerPage;
        if (('' !== this.searchString) && ('' !== this.searchParameter)) {
            query[this.searchParameter] = this.searchString;
        }
        return query;
    }
}
exports.default = Filters;
