"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarcassFileState = void 0;
class CarcassFileState {
    constructor({ loading, carcassFiles }) {
        this.loading = loading;
        this.carcassFiles = carcassFiles;
    }
    static factory() {
        return new CarcassFileState({
            loading: false,
            carcassFiles: []
        });
    }
}
exports.CarcassFileState = CarcassFileState;
