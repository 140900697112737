'use strict';
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const ProductPopupState_1 = require("./ProductPopupState");
const MutationTypes = __importStar(require("../productPopup/mutation-types"));
const i18n_1 = require("../../common/i18n");
const ShopConfig_1 = require("../../common/utils/ShopConfig");
const axaptaPopupHost = ShopConfig_1.ShopConfig.getAxaptaPopupHost();
const dataUrl = axaptaPopupHost + '/axapta-product-popup/%productId%?jsonFormat=1&locale=%locale%';
const state = ProductPopupState_1.ProductPopupState.factory();
const getters = {
    modalIsOpen: (state) => state.modalIsOpen(),
    modalIsLoading: (state) => state.modalIsLoading(),
    isNotFound: (state) => state.isNotFound(),
    product: (state) => state.product,
    productSku: (state) => state.productSku,
    productData: (state) => state.productData,
};
const mutations = {
    [MutationTypes.PRODUCT_POPUP_SHOW](state, payload) {
        state.product = payload.product;
        state.productSku = payload.product.sku;
        state.widgetState = ProductPopupState_1.Statuses.showing;
        state.productData = payload.productData;
    },
    [MutationTypes.PRODUCT_POPUP_SUSPEND](state) {
        state.widgetState = ProductPopupState_1.Statuses.suspended;
    },
    [MutationTypes.PRODUCT_POPUP_LOAD](state) {
        state.product = null;
        state.productSku = '';
        state.widgetState = ProductPopupState_1.Statuses.loading;
    },
    [MutationTypes.PRODUCT_POPUP_NOT_FOUND](state, payload) {
        state.product = payload.product;
        state.productSku = payload.productId;
        state.widgetState = ProductPopupState_1.Statuses.notFound;
        state.productData = payload.productData;
    },
};
const actions = {
    beginProcess({ commit }, productData) {
        let productId = productData.id;
        if ('' === productId) {
            return;
        }
        let locale = i18n_1.i18n.locale.value;
        commit({ type: MutationTypes.PRODUCT_POPUP_LOAD });
        return new Promise((resolve, reject) => {
            fetch(dataUrl
                .replace('%productId%', productId)
                .replace('%locale%', locale)).then(response => {
                if (200 === response.status) {
                    let result = response.json();
                    result.then(product => {
                        commit({ type: MutationTypes.PRODUCT_POPUP_SHOW, product, productData });
                    });
                    resolve(response);
                    return;
                }
                if (404 === response.status) {
                    let result = response.json();
                    result.then(product => {
                        commit({ type: MutationTypes.PRODUCT_POPUP_NOT_FOUND, product, productData, productId });
                    });
                    resolve(response);
                    return;
                }
                reject(response);
            }).catch(error => {
                reject(error);
            });
        });
    },
    close({ commit }) {
        commit({ type: MutationTypes.PRODUCT_POPUP_SUSPEND });
    },
};
exports.default = {
    state,
    getters,
    mutations,
    actions
};
