"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiscountPopupState = exports.Statuses = void 0;
var Statuses;
(function (Statuses) {
    Statuses[Statuses["suspended"] = 0] = "suspended";
    Statuses[Statuses["loading"] = 1] = "loading";
    Statuses[Statuses["notFound"] = 2] = "notFound";
    Statuses[Statuses["showing"] = 3] = "showing";
})(Statuses = exports.Statuses || (exports.Statuses = {}));
class DiscountPopupState {
    constructor() {
        this.widgetState = Statuses.suspended;
        this.discount = null;
        this.loadedDiscounts = [];
    }
    modalIsOpen() {
        return this.widgetState !== Statuses.suspended;
    }
    modalIsLoading() {
        return this.widgetState === Statuses.loading;
    }
    isNotFound() {
        return this.widgetState === Statuses.notFound;
    }
    static factory() {
        return new DiscountPopupState();
    }
}
exports.DiscountPopupState = DiscountPopupState;
