"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AxaptaGuaranteeProductReturnStatusEnum = void 0;
var AxaptaGuaranteeProductReturnStatusEnum;
(function (AxaptaGuaranteeProductReturnStatusEnum) {
    AxaptaGuaranteeProductReturnStatusEnum[AxaptaGuaranteeProductReturnStatusEnum["ALL"] = -1] = "ALL";
    AxaptaGuaranteeProductReturnStatusEnum[AxaptaGuaranteeProductReturnStatusEnum["REGISTERED"] = 0] = "REGISTERED";
    AxaptaGuaranteeProductReturnStatusEnum[AxaptaGuaranteeProductReturnStatusEnum["IN_PROCESS"] = 1] = "IN_PROCESS";
    AxaptaGuaranteeProductReturnStatusEnum[AxaptaGuaranteeProductReturnStatusEnum["ACCEPTED"] = 2] = "ACCEPTED";
    AxaptaGuaranteeProductReturnStatusEnum[AxaptaGuaranteeProductReturnStatusEnum["CLOSED"] = 3] = "CLOSED";
    AxaptaGuaranteeProductReturnStatusEnum[AxaptaGuaranteeProductReturnStatusEnum["REJECTED"] = 4] = "REJECTED";
    AxaptaGuaranteeProductReturnStatusEnum[AxaptaGuaranteeProductReturnStatusEnum["ESCALATED"] = 5] = "ESCALATED";
})(AxaptaGuaranteeProductReturnStatusEnum = exports.AxaptaGuaranteeProductReturnStatusEnum || (exports.AxaptaGuaranteeProductReturnStatusEnum = {}));
