"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderInvoicesState = void 0;
class OrderInvoicesState {
    constructor({ loading, orderInvoices }) {
        this.loading = loading;
        this.orderInvoices = orderInvoices;
    }
    static factory() {
        return new OrderInvoicesState({
            loading: false,
            orderInvoices: []
        });
    }
}
exports.OrderInvoicesState = OrderInvoicesState;
