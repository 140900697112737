/** @type {import('tailwindcss').Config} */
module.exports = {
    content: [
        './assets/**/*.{ts,vue,scss}',
        './templates/**/*.twig',
    ],
    theme: {
        extend: {
            zIndex: {
                '-1': '-1'
            },
            borderWidth: {
                '1': '1px',
                '3': '3px',
                '5': '5px',
                '6': '6px',
                '7': '7px'
            },
            screens: {
                'navbar-breakpoint-1': '850px',
                'navbar-breakpoint-2': '950px',
                'navbar-breakpoint-3': '1050px',
                'navbar-breakpoint-4': '1150px'
            }
        },
        fontFamily: {
            'sans': [
                '-apple-system', // Safari for OS X and iOS (San Francisco)
                'BlinkMacSystemFont', // Chrome < 56 for OS X (San Francisco)
                'Montserrat', // default web
                'Roboto', // Android
                '"Helvetica Neue"', 'Arial', '"Noto Sans"', 'sans-serif', // Basic web fallback
                '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"', '"Noto Color Emoji"' // Emoji fonts
            ],
            'mono': [
                'ui-monospace', 'SFMono-Regular',
                '"Fira Code"',
                'Menlo', 'Monaco', 'Consolas', '"Liberation Mono"', '"Courier New"', 'monospace'
            ]
        },
        colors: {
            'transparent': 'transparent',
            'white': '#FFFFFF',
            'black': '#000000',
            'gray': {
                DEFAULT: '#E1E1E1', // marso gray
                'dark': '#929292',
                'darker': '#4A4A4A',
                'darkest': '#2A2A2A',
            },
            'red': {
                DEFAULT: '#DC2F33', // marso red
                'light': '#e3595c',
                'dark': '#b71f22',
            },
            'green': {
                DEFAULT: '#55AC4E',
                'light': '#74be6f',
                'dark': '#43893e',
            },
            'blue': {
                DEFAULT: '#4552A2',
                'light': '#5e6aba',
                'dark': '#363f7d',
            },
            'peach': {
                DEFAULT: '#EC4E1C',
                'light': '#f0724c',
                'dark': '#c63b10',
            },
            'orange': {
                DEFAULT: '#ffc107',
                'light': '#ffcd38',
                'dark': '#d19d00',
            },
            purple: require('tailwindcss/colors').purple
        },
        boxShadow: {
            'none': 'none',
            'inner': 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.3)',
            'sm': '0px 2px 5px rgba(0, 0, 0, 0.3)',
            'DEFAULT': '0px 3px 10px rgba(0, 0, 0, 0.3)',
            'small-box': '0px 0px 2px 1px rgba(0, 0, 0, 0.3)',
        }
    },
    plugins: []
};
