"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToastPosition = void 0;
var ToastPosition;
(function (ToastPosition) {
    ToastPosition["TopLeft"] = "top-left";
    ToastPosition["TopRight"] = "top-right";
    ToastPosition["BottomLeft"] = "bottom-left";
    ToastPosition["BottomRight"] = "bottom-right";
    ToastPosition["Center"] = "center";
})(ToastPosition = exports.ToastPosition || (exports.ToastPosition = {}));
