"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isBlank = exports.isValidEmail = void 0;
function isValidEmail(email) {
    if (email === null || email === undefined) {
        return false;
    }
    let accentedLetters = [
        'á', 'Á',
        'é', 'É',
        'í', 'Í',
        'ó', 'Ó',
        'ö', 'Ö',
        'ő', 'Ő',
        'ú', 'Ú',
        'ü', 'Ü',
        'ű', 'Ű'
    ];
    let containAccentedLetter = accentedLetters.some((accentedLetter) => {
        return email.includes(accentedLetter);
    });
    if (containAccentedLetter) {
        return false;
    }
    return /\S+@\S+\.\S+/.test(email);
}
exports.isValidEmail = isValidEmail;
function isBlank(text) {
    return text === null || text === undefined || text === '';
}
exports.isBlank = isBlank;
