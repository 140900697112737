"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerProductReturnCreateRequest = void 0;
const validators_1 = require("../../common/utils/validators");
class CustomerProductReturnCreateRequest {
    constructor(customerName = '', shippingMode = null, transferName = '', transferPhone = '', shippingAddress = '', shippingZip = '', shippingCity = '', shippingStreet = '', customerEqualsEndUser = true, productId = '', invoiceId = '', quantity = null, dotNumber = null, reasonCode = '', reasonMessage = '', filterpid = '', reasonCodeText = '', customerAddressId = '') {
        this.customerName = customerName;
        this.shippingMode = shippingMode;
        this.transferName = transferName;
        this.transferPhone = transferPhone;
        this.shippingAddress = shippingAddress;
        this.shippingZip = shippingZip;
        this.shippingCity = shippingCity;
        this.shippingStreet = shippingStreet;
        this.customerEqualsEndUser = customerEqualsEndUser;
        this.productId = productId;
        this.invoiceId = invoiceId;
        this.quantity = quantity;
        this.dotNumber = dotNumber;
        this.reasonCode = reasonCode;
        this.reasonMessage = reasonMessage;
        this.filterpid = filterpid;
        this.reasonCodeText = reasonCodeText;
        this.customerAddressId = customerAddressId;
    }
    isShippingStreetValid() {
        return !(0, validators_1.isBlank)(this.shippingStreet);
    }
    isTransferNameValid() {
        return !(0, validators_1.isBlank)(this.transferName);
    }
    isTransferPhoneValid() {
        return !(0, validators_1.isBlank)(this.transferPhone);
    }
    isCustomerNameValid() {
        return !(0, validators_1.isBlank)(this.customerName);
    }
    isShippingModeValid() {
        return !(0, validators_1.isBlank)(this.shippingMode);
    }
    isShippingAddressValid() {
        return !(0, validators_1.isBlank)(this.shippingAddress);
    }
    isProductIdValid() {
        return !(0, validators_1.isBlank)(this.productId);
    }
    isInvoiceIdValid() {
        return !(0, validators_1.isBlank)(this.invoiceId);
    }
    isQuantityValid() {
        return !(0, validators_1.isBlank)(this.quantity);
    }
    isDOTNumberValid() {
        return !(0, validators_1.isBlank)(this.dotNumber);
    }
    isReasonCodeValid() {
        return !(0, validators_1.isBlank)(this.reasonCode);
    }
    isReasonMessageValid() {
        return !(0, validators_1.isBlank)(this.reasonMessage);
    }
    isValid() {
        return this.isCustomerNameValid()
            && this.isShippingModeValid()
            && this.isShippingAddressValid()
            && this.isProductIdValid()
            && this.isInvoiceIdValid()
            && this.isQuantityValid()
            && this.isDOTNumberValid()
            && this.isReasonCodeValid()
            && this.isReasonMessageValid()
            && this.isTransferNameValid()
            && this.isTransferPhoneValid();
    }
}
exports.CustomerProductReturnCreateRequest = CustomerProductReturnCreateRequest;
