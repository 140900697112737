"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BannerFileState = void 0;
class BannerFileState {
    constructor({ loading, bannerFiles }) {
        this.loading = loading;
        this.bannerFiles = bannerFiles;
    }
    static factory() {
        return new BannerFileState({
            loading: false,
            bannerFiles: [],
        });
    }
}
exports.BannerFileState = BannerFileState;
