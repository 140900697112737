"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SEARCH_BANNER_PAGES = exports.SKU_SEARCH = exports.ACCESSORIES = exports.TUBE_AND_TAPE_PRODUCT = exports.SPARE_WHEELS = exports.RIM_SEARCH = exports.RIM_SEARCH_BY_CAR = exports.MOTORCYCLE_TYRES = exports.FORKLIFT_TYRES = exports.AGRICULTURAL_TYRES = exports.TRUCK_TYRES = exports.PASSENGER_TYRES = void 0;
exports.PASSENGER_TYRES = "shop_passenger_tyres";
exports.TRUCK_TYRES = "shop_truck_tyres";
exports.AGRICULTURAL_TYRES = "shop_agricultural_and_industrial_tyres";
exports.FORKLIFT_TYRES = "shop_forklift_tyres";
exports.MOTORCYCLE_TYRES = "shop_motorcycle_tyres";
exports.RIM_SEARCH_BY_CAR = "shop_rim_search_by_car";
exports.RIM_SEARCH = "shop_rim_search";
exports.SPARE_WHEELS = "shop_spare_wheel";
exports.TUBE_AND_TAPE_PRODUCT = "shop_tube_and_tape_product_search";
exports.ACCESSORIES = "shop_accessories_search";
exports.SKU_SEARCH = "shop_sku_search";
exports.SEARCH_BANNER_PAGES = [
    exports.PASSENGER_TYRES,
    exports.TRUCK_TYRES,
    exports.AGRICULTURAL_TYRES,
    exports.FORKLIFT_TYRES,
    exports.MOTORCYCLE_TYRES,
    exports.RIM_SEARCH_BY_CAR,
    exports.RIM_SEARCH,
    exports.SPARE_WHEELS,
    exports.TUBE_AND_TAPE_PRODUCT,
    exports.ACCESSORIES,
    exports.SKU_SEARCH
];
