"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RIM_SEARCH_BY_CAR_VALVE_STOCK_DETAILS_HIDE = exports.RIM_SEARCH_BY_CAR_VALVE_STOCK_DETAILS_SHOW = exports.RIM_SEARCH_BY_CAR_VALVE_STOCK_DETAILS_ADD = exports.RIM_SEARCH_BY_CAR_VALVE_STOCK_DETAILS_LOADING = exports.RIM_SEARCH_BY_CAR_RIM_STOCK_DETAILS_HIDE = exports.RIM_SEARCH_BY_CAR_RIM_STOCK_DETAILS_SHOW = exports.RIM_SEARCH_BY_CAR_RIM_STOCK_DETAILS_ADD = exports.RIM_SEARCH_BY_CAR_RIM_STOCK_DETAILS_LOADING = exports.RIM_SEARCH_BY_CAR_SELECTED_RIM_CHANGED = exports.RIM_SEARCH_BY_CAR_RIM_SIZE_CHANGED = exports.RIM_SEARCH_BY_CAR_RIM_TPMS_CHANGED = exports.RIM_SEARCH_BY_CAR_REWIND_TO = exports.RIM_SEARCH_BY_CAR_LOAD_RIMS = exports.RIM_SEARCH_BY_CAR_LOAD_MODELS = exports.RIM_SEARCH_BY_CAR_LOAD_TYPES = exports.RIM_SEARCH_BY_CAR_LOAD_BRANDS = void 0;
exports.RIM_SEARCH_BY_CAR_LOAD_BRANDS = 'RIM_SEARCH_BY_CAR_LOAD_BRANDS';
exports.RIM_SEARCH_BY_CAR_LOAD_TYPES = 'RIM_SEARCH_BY_CAR_LOAD_TYPES';
exports.RIM_SEARCH_BY_CAR_LOAD_MODELS = 'RIM_SEARCH_BY_CAR_LOAD_MODELS';
exports.RIM_SEARCH_BY_CAR_LOAD_RIMS = 'RIM_SEARCH_BY_CAR_LOAD_RIMS';
exports.RIM_SEARCH_BY_CAR_REWIND_TO = 'RIM_SEARCH_BY_CAR_REWIND_TO';
exports.RIM_SEARCH_BY_CAR_RIM_TPMS_CHANGED = 'RIM_SEARCH_BY_CAR_RIM_TPMS_CHANGED';
exports.RIM_SEARCH_BY_CAR_RIM_SIZE_CHANGED = 'RIM_SEARCH_BY_CAR_RIM_SIZE_CHANGED';
exports.RIM_SEARCH_BY_CAR_SELECTED_RIM_CHANGED = 'RIM_SEARCH_BY_CAR_SELECTED_RIM_CHANGED';
exports.RIM_SEARCH_BY_CAR_RIM_STOCK_DETAILS_LOADING = 'RIM_SEARCH_BY_CAR_RIM_STOCK_DETAILS_LOADING';
exports.RIM_SEARCH_BY_CAR_RIM_STOCK_DETAILS_ADD = 'RIM_SEARCH_BY_CAR_RIM_STOCK_DETAILS_ADD';
exports.RIM_SEARCH_BY_CAR_RIM_STOCK_DETAILS_SHOW = 'RIM_SEARCH_BY_CAR_RIM_STOCK_DETAILS_SHOW';
exports.RIM_SEARCH_BY_CAR_RIM_STOCK_DETAILS_HIDE = 'RIM_SEARCH_BY_CAR_RIM_STOCK_DETAILS_HIDE';
exports.RIM_SEARCH_BY_CAR_VALVE_STOCK_DETAILS_LOADING = 'RIM_SEARCH_BY_CAR_VALVE_STOCK_DETAILS_LOADING';
exports.RIM_SEARCH_BY_CAR_VALVE_STOCK_DETAILS_ADD = 'RIM_SEARCH_BY_CAR_VALVE_STOCK_DETAILS_ADD';
exports.RIM_SEARCH_BY_CAR_VALVE_STOCK_DETAILS_SHOW = 'RIM_SEARCH_BY_CAR_VALVE_STOCK_DETAILS_SHOW';
exports.RIM_SEARCH_BY_CAR_VALVE_STOCK_DETAILS_HIDE = 'RIM_SEARCH_BY_CAR_VALVE_STOCK_DETAILS_HIDE';
