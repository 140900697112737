"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackingSlipsState = void 0;
class PackingSlipsState {
    constructor({ packingSlips, packingSlipItems }) {
        this.packingSlips = packingSlips;
        this.packingSlipItems = packingSlipItems;
    }
    static factory() {
        return new PackingSlipsState({
            packingSlips: [],
            packingSlipItems: []
        });
    }
}
exports.PackingSlipsState = PackingSlipsState;
