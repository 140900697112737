"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class NewsRequest {
    constructor(locale = null, title = '', description = '', body = '', category = [], createdUser = '', //IRI
    originalFilename = null, base64EncodedFile = null) {
        this.locale = locale;
        this.title = title;
        this.description = description;
        this.body = body;
        this.category = category;
        this.createdUser = createdUser;
        this.originalFilename = originalFilename;
        this.base64EncodedFile = base64EncodedFile;
    }
}
exports.default = NewsRequest;
