"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { key: 0 };
const _hoisted_2 = ["href"];
const _hoisted_3 = ["src"];
const _hoisted_4 = ["href"];
const vue_3 = require("vue");
const vuex_1 = require("vuex");
const toastStore_1 = require("../../stores/toast/toastStore");
const securityStore_1 = require("../../stores/security/securityStore");
const PermissionEnum_1 = require("../../stores/permission/PermissionEnum");
exports.default = (0, vue_1.defineComponent)({
    __name: 'HighlightedNewsletters',
    setup(__props) {
        const store = (0, vuex_1.useStore)();
        const toastStore = (0, toastStore_1.useToastStore)();
        const securityStore = (0, securityStore_1.useSecurityStore)();
        const currentUser = (0, vue_3.computed)(() => {
            return securityStore.authenticatedUser;
        });
        const highlightedNewsletters = (0, vue_3.computed)(() => store.getters["newsletters/highlightedNewsletters"]);
        (0, vue_3.onBeforeMount)(() => {
            if (!currentUser.value)
                return;
            if (!currentUser.value.hasPermission(PermissionEnum_1.NewsletterPermissionEnum.NEWSLETTER_READ))
                return;
            store.dispatch("newsletters/getHighlightedNewsletters", {
                page: 1,
                itemsPerPage: 10,
                "order[id]": 'desc'
            })
                .catch((error) => { toastStore.addErrorToast(error.toString()); });
        });
        return (_ctx, _cache) => {
            var _a;
            return ((_a = (0, vue_2.unref)(currentUser)) === null || _a === void 0 ? void 0 : _a.hasPermission((0, vue_2.unref)(PermissionEnum_1.NewsletterPermissionEnum).NEWSLETTER_READ))
                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                    ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)((0, vue_2.unref)(highlightedNewsletters), (newsletter) => {
                        return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                            key: newsletter.id,
                            class: "p-2 bg-white shadow mb-7"
                        }, [
                            (0, vue_2.createElementVNode)("a", {
                                class: "block",
                                href: newsletter.fileUrl,
                                target: "_blank"
                            }, [
                                (0, vue_2.createElementVNode)("img", {
                                    src: newsletter.thumbnailUrl
                                }, null, 8, _hoisted_3)
                            ], 8, _hoisted_2),
                            (0, vue_2.createElementVNode)("a", {
                                class: "block text-center font-bold",
                                href: newsletter.fileUrl,
                                target: "_blank"
                            }, (0, vue_2.toDisplayString)(newsletter.title), 9, _hoisted_4)
                        ]));
                    }), 128))
                ]))
                : (0, vue_2.createCommentVNode)("", true);
        };
    }
});
