"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class CartsState {
    constructor(carts = [], activeCartId = null, activeCartModalVisible = false) {
        this.carts = carts;
        this.activeCartId = activeCartId;
        this.activeCartModalVisible = activeCartModalVisible;
    }
    static factory() {
        return new CartsState([], null, false);
    }
    getActiveCart() {
        return this.activeCartId === null ? undefined : this.getCart(this.activeCartId);
    }
    getCart(cartId) {
        return this.carts.find((cart) => cart.id === cartId);
    }
}
exports.default = CartsState;
