"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ServerError extends Error {
    constructor(message, response, responseData) {
        super(message);
        this.response = response;
        this.responseData = responseData;
    }
    getStatusCode() {
        return this.response.status;
    }
    toString() {
        return this.responseData['hydra:description'];
    }
}
exports.default = ServerError;
