"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Option {
    constructor(value, text, selected) {
        this.value = value;
        this.text = text;
        this.selected = selected;
    }
}
exports.default = Option;
