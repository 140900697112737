"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AxaptaCustomerProductReturnStatusEnum = void 0;
var AxaptaCustomerProductReturnStatusEnum;
(function (AxaptaCustomerProductReturnStatusEnum) {
    AxaptaCustomerProductReturnStatusEnum[AxaptaCustomerProductReturnStatusEnum["ALL"] = -1] = "ALL";
    AxaptaCustomerProductReturnStatusEnum[AxaptaCustomerProductReturnStatusEnum["REGISTERED"] = 0] = "REGISTERED";
    AxaptaCustomerProductReturnStatusEnum[AxaptaCustomerProductReturnStatusEnum["IN_PROCESS"] = 1] = "IN_PROCESS";
    AxaptaCustomerProductReturnStatusEnum[AxaptaCustomerProductReturnStatusEnum["ACCEPTED"] = 2] = "ACCEPTED";
    AxaptaCustomerProductReturnStatusEnum[AxaptaCustomerProductReturnStatusEnum["CLOSED"] = 3] = "CLOSED";
    AxaptaCustomerProductReturnStatusEnum[AxaptaCustomerProductReturnStatusEnum["REJECTED"] = 4] = "REJECTED";
    AxaptaCustomerProductReturnStatusEnum[AxaptaCustomerProductReturnStatusEnum["ESCALATED"] = 5] = "ESCALATED";
})(AxaptaCustomerProductReturnStatusEnum = exports.AxaptaCustomerProductReturnStatusEnum || (exports.AxaptaCustomerProductReturnStatusEnum = {}));
