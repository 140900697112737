"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewsletterRequest = void 0;
class NewsletterRequest {
    constructor(title = '', category = [], originalFilename = '', base64EncodedFile = null) {
        this.title = title;
        this.category = category;
        this.originalFilename = originalFilename;
        this.base64EncodedFile = base64EncodedFile;
    }
}
exports.NewsletterRequest = NewsletterRequest;
