"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ProductFilter_1 = require("../../../models/ProductFilter");
const ProductFilterState_1 = require("./ProductFilterState");
const storeObjects_1 = require("./storeObjects");
const state = ProductFilterState_1.ProductFilterState.factory(ProductFilter_1.SkuProductFilters);
exports.default = {
    namespaced: true,
    state,
    mutations: storeObjects_1.mutations,
    getters: storeObjects_1.getters,
    actions: storeObjects_1.actions,
};
