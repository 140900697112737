"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Filters {
    constructor() {
        this.page = 1;
        this.status = [];
        this.action = '';
        this.cartId = '';
        this.customerId = '';
        this.orderId = '';
        this.userId = '';
        this.orderBy = 'createdAt';
        this.orderDirection = 'desc';
        this.itemsPerPage = 10;
    }
    createQuery() {
        let query = {};
        query.page = this.page;
        query.order = [];
        query.order[this.orderBy] = this.orderDirection;
        if (this.status.length) {
            query.status = [];
            this.status.forEach((item) => {
                query.status.push(item);
            });
        }
        if ('' !== this.action) {
            query.action = this.action;
        }
        if ('' !== this.cartId) {
            query.cartId = this.cartId;
        }
        if ('' !== this.customerId) {
            query.customerId = this.customerId;
        }
        if ('' !== this.orderId) {
            query.orderId = this.orderId;
        }
        if ('' !== this.userId) {
            query.userId = this.userId;
        }
        query.itemsPerPage = this.itemsPerPage;
        return query;
    }
}
exports.default = Filters;
