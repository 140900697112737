"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "flex flex-col w-full justify-center" };
const _hoisted_2 = {
    key: 0,
    class: "block pb-2 font-bold uppercase tracking-wide text-sm"
};
const _hoisted_3 = { class: "relative w-full flex flex-row items-center" };
const _hoisted_4 = ["placeholder", "value"];
const _hoisted_5 = {
    key: 1,
    class: "text-red mt-2 text-sm"
};
const vue_3 = require("vue");
const vuex_1 = require("vuex");
const toastStore_1 = require("../../stores/toast/toastStore");
const i18n_1 = require("../../common/i18n");
exports.default = (0, vue_1.defineComponent)({
    __name: 'QuickDateInput',
    props: {
        modelValue: {
            type: [String, () => null],
            default: null,
        },
        label: {
            type: String,
            required: false,
            default: "",
        },
        showValidity: {
            type: Boolean,
            default: false,
        },
        valid: {
            type: Boolean,
            default: true,
            required: false,
        },
        invalidMessage: {
            type: String,
            required: false,
            default: "",
        },
    },
    emits: ["update:modelValue"],
    setup(__props, { emit }) {
        const props = __props;
        const store = (0, vuex_1.useStore)();
        const toastStore = (0, toastStore_1.useToastStore)();
        const quickDateInput = (0, vue_3.ref)(null);
        const formatDate = () => {
            var _a;
            if (quickDateInput.value === null) {
                return;
            }
            const value = quickDateInput.value.value;
            let date = null;
            let year = null;
            let month = null;
            let day = null;
            if (value.length === 8) {
                year = value.slice(0, 4);
                month = value.slice(4, 6);
                day = value.slice(6, 8);
                date = new Date(`${year}-${month}-${day}`);
            }
            if (value.length === 10) {
                year = value.slice(0, 4);
                const dash1 = value.slice(4, 5);
                month = value.slice(5, 7);
                const dash2 = value.slice(7, 8);
                day = value.slice(8, 10);
                if (dash1 === "-" && dash2 === "-") {
                    date = new Date(`${year}-${month}-${day}`);
                }
            }
            if (date === null || date.toString() === "Invalid Date") {
                toastStore.addErrorToast((_a = (0, i18n_1.trans)("invalid_date_format", "Invalid Date format! Use YYYYMMDD like 20230303", "quick_date_input")) !== null && _a !== void 0 ? _a : "Invalid Date format! Use YYYYMMDD like 20230303");
                quickDateInput.value.value = "";
                emit("update:modelValue", "");
                return;
            }
            quickDateInput.value.value = `${year}-${month}-${day}`;
            emit("update:modelValue", quickDateInput.value.value);
        };
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("label", _hoisted_1, [
                (__props.label)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_2, (0, vue_2.toDisplayString)(__props.label), 1))
                    : (0, vue_2.createCommentVNode)("", true),
                (0, vue_2.createElementVNode)("div", _hoisted_3, [
                    (0, vue_2.createElementVNode)("input", {
                        type: "text",
                        ref_key: "quickDateInput",
                        ref: quickDateInput,
                        required: "",
                        pattern: "[0-9]{8}",
                        class: (0, vue_2.normalizeClass)(["block py-2 px-2 w-full placeholder-gray-dark text-[15px] outline-none transition-all duration-300 disabled:bg-gray h-12 border border-gray-dark border-opacity-50 focus:border-blue", {
                                'border-green': __props.valid && __props.showValidity,
                                'border-red': !__props.valid && __props.showValidity,
                            }]),
                        placeholder: (0, vue_2.unref)(i18n_1.trans)('quick_date_input_placeholder', 'YYYYMMDD or YYYY-MM-DD', 'quick_date_input'),
                        value: __props.modelValue,
                        onInput: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('update:modelValue', $event.target
                            ? $event.target.value
                            : ''))),
                        onBlur: formatDate
                    }, null, 42, _hoisted_4)
                ]),
                (__props.showValidity && __props.invalidMessage !== '' && !__props.valid)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_5, (0, vue_2.toDisplayString)(__props.invalidMessage), 1))
                    : (0, vue_2.createCommentVNode)("", true)
            ]));
        };
    }
});
