"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = ["id"];
const _hoisted_2 = {
    key: 0,
    class: "flex grow flex-col items-start justify-center content-center whitespace-nowrap overflow-hidden"
};
const _hoisted_3 = {
    key: 2,
    class: "marso-icon-chevron-down transform-gpu transition-transform text-xs",
    "aria-hidden": "true"
};
const vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'DropdownMenu',
    props: {
        buttonText: {
            type: String,
            default: ''
        },
        right: {
            type: Boolean,
            default: false
        },
        hideCaret: {
            type: Boolean,
            default: false
        },
        mobileFullWidth: {
            type: Boolean,
            default: false
        }
    },
    emits: ['selected'],
    setup(__props, { expose, emit }) {
        var _a;
        const activeMenu = (0, vue_3.ref)(false);
        const dropdown = (0, vue_3.ref)(null);
        const componentId = (_a = (0, vue_3.getCurrentInstance)()) === null || _a === void 0 ? void 0 : _a.uid;
        const clickOutsideListener = (event) => {
            if (dropdown.value !== null && !dropdown.value.contains(event.target)) {
                close();
            }
        };
        (0, vue_3.onMounted)(() => {
            dropdown.value = document.getElementById(`dropdown_${componentId}`);
            document.addEventListener('click', clickOutsideListener);
        });
        (0, vue_3.onUnmounted)(() => {
            document.removeEventListener('click', clickOutsideListener);
        });
        const close = () => {
            activeMenu.value = false;
        };
        const itemSelected = () => {
            close();
            emit('selected');
        };
        const toggleMenu = () => {
            activeMenu.value = !activeMenu.value;
        };
        expose({ close });
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                id: `dropdown_${(0, vue_2.unref)(componentId)}`,
                class: (0, vue_2.normalizeClass)(["relative dropdown hoverable", {
                        active: activeMenu.value,
                        'mobile-full-width': __props.mobileFullWidth
                    }]),
                ref_key: "dropdown",
                ref: dropdown
            }, [
                (0, vue_2.createElementVNode)("span", {
                    class: "h-full flex items-center justify-start gap-1 xl:gap-2 cursor-pointer",
                    onClick: _cache[0] || (_cache[0] = ($event) => (toggleMenu()))
                }, [
                    (__props.buttonText.length)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_2, [
                            (0, vue_2.createElementVNode)("div", null, (0, vue_2.toDisplayString)(__props.buttonText), 1)
                        ]))
                        : (0, vue_2.renderSlot)(_ctx.$slots, "buttonText", { key: 1 }),
                    (!__props.hideCaret)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("i", _hoisted_3))
                        : (0, vue_2.createCommentVNode)("", true)
                ]),
                (0, vue_2.createElementVNode)("div", {
                    class: (0, vue_2.normalizeClass)(["dropdown-menu rounded-b-md", {
                            '-ml-2': !__props.right,
                            'right-0': __props.right
                        }])
                }, [
                    (0, vue_2.renderSlot)(_ctx.$slots, "default", { itemSelected: itemSelected })
                ], 2)
            ], 10, _hoisted_1));
        };
    }
});
